const actions = {
  FETCH_ALL_TRACKABLE: 'trackable/fetch_all',
  FETCH_ALL_TRACKABLE_SUCCESS: 'trackable/fetch_all_success',
  FETCH_ALL_TRACKABLE_FAILURE: 'trackable/fetch_all_failure',
  FETCH_ALL_TRACKABLE_REPORT: 'trackable_report/fetch_all',
  FETCH_ALL_TRACKABLE_REPORT_SUCCESS: 'trackable_report/fetch_all_success',
  FETCH_ALL_TRACKABLE_REPORT_FAILURE: 'trackable_report/fetch_all_failure',
  CLEAR_ALL_TRACKABLE_REPORT: 'trackable/clear_all',
  FETCH_RECENT_HISTORY: 'trackable_report/fetch_resent_history',
  FETCH_RECENT_HISTORY_SUCCESS: 'trackable_report/fetch_resent_history_success',
  FETCH_RECENT_HISTORY_FAILURE: 'trackable_report/fetch_resent_history_failure',
}

export default actions
