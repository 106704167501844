import actions from './actions'
import { has } from 'lodash'
import { drawModeProp } from 'pages/dashboard/networkManagement/interface'
import { Layerv2 as Layer } from 'config/interfaces'

export const updateLayers = (nmLayers: Layer[], selectedLayer: Layer, beaconData) => {
  if (nmLayers) {
    return nmLayers.map((layer) => {
      return layer.id === selectedLayer.id ? { ...layer, beacons: beaconData } : layer
    })
  }
  return []
}

interface StateProp {
  showDrawer: boolean
  selectedLayer: Layer | undefined
  nmLayers: Layer[] | null
  drawMode: drawModeProp
  isFetching: boolean
  lastFetchFailed: boolean
  lastFetchStartTime: undefined | string
  lastFetchSuccessTime: undefined | string
  firstFetchFailureTime: undefined | string
  layersBeforeSubmission: Layer[]
  beaconIsSubmitting: boolean
  isBeaconRelocating: drawModeProp
  layersIsSubmitting: boolean
}

export const initialState: StateProp = {
  showDrawer: false,
  selectedLayer: undefined,
  nmLayers: null,
  drawMode: { id: null },
  isFetching: false,
  lastFetchFailed: false,
  lastFetchStartTime: undefined,
  lastFetchSuccessTime: undefined,
  firstFetchFailureTime: undefined,
  layersBeforeSubmission: undefined,
  beaconIsSubmitting: false,
  isBeaconRelocating: { id: null },
  layersIsSubmitting: false,
}

export default function networkManagementReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_LAYERS:
      return {
        ...state,
        isFetching: true,
        lastFetchStartTime: new Date(),
      }
    case actions.FETCH_ALL_LAYERS_V2:
      return {
        ...state,
        isFetching: true,
        lastFetchStartTime: new Date(),
      }
    case actions.FETCH_ALL_LAYERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastFetchFailed: false,
        lastFetchSuccessTime: new Date(),
        nmLayers: action.payload,
        layersBeforeSubmission: action.payload,
      }
    case actions.FETCH_ALL_LAYERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastFetchFailed: true,
        firstFetchFailureTime: state.firstFetchFailureTime || new Date(),
      }
    case actions.UPDATE_NM_STATE:
      return { ...state, ...action.payload }
    case actions.HANDLE_BEACON_CHANGE:
      const updatedLayers = updateLayers(
        state.nmLayers,
        state.selectedLayer,
        action.payload.beaconData
      )
      return {
        ...state,
        nmLayers: updatedLayers,
        selectedLayer: updatedLayers.find(layer => layer.id === state.selectedLayer.id),
        drawMode: has(action.payload, 'drawMode') ? action.payload.drawMode : state.drawMode,
        isBeaconRelocating: has(action.payload, 'isBeaconRelocating')
          ? action.payload.isBeaconRelocating
          : state.isBeaconRelocating,
      }
    case actions.ADD_LAYERS:
      console.log('ADD_LAYERS')
      return {
        ...state,
        isSubmitting: true,
      }
    case actions.ADD_LAYERS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      }
    case actions.ADD_LAYERS_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      }
    case actions.RESET_STATE:
      return { ...initialState, layersBeforeSubmission: state.layersBeforeSubmission }
    default:
      return state
  }
}
