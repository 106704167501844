import ynomiaclient from '../Client'
import { keyBy } from '../../utils'
import { Zone, ZonesMap, ScreenProp } from '../../config/interfaces'
import { flatten, groupBy } from 'lodash'
import { checkFeature } from 'services/FeatureAccess'
import ZoneHelper from 'pages/dashboard/zones/helper'

/**
 * This service manages the management of Operational Safety Zones.
 */
export default class Zones {
  static useClient: any
  static zoneHelper: any
  /**
   * Fetches the entire list of Operational Safety Zones from the backend.
   * @return {Promise<ZonesMap>} Zone data as an object (keyed by the zone id).
   */
  // Layers.useClient.cache.current.bootstrap
  static async fetchAll(client?: any): Promise<ZonesMap> {
    Zones.useClient = client ? client : ynomiaclient
    Zones.zoneHelper = new ZoneHelper()

    try {
      const bootstrap = Zones.useClient.cache.current.bootstrap
      const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
      const response = await Zones.useClient.server.post(
        `/scratch/telemetry/search/${tenant}/${scratchProjectCode}`,
        {
          assetType: ['zone', 'screen'],
          collections: { monitorable: true },
          timeout: 10000,
        }
      )
      const responseGroup = groupBy(response?.data?.value, 'type')
      const zones = responseGroup[`${tenant}::${scratchProjectCode}::zone`]
      let screenWithZone: any = []
      let screens: Array<Object> = []
      let keyByScreens = {}

      if (Object.keys(responseGroup).includes(`${tenant}::${scratchProjectCode}::screen`)) {
        screens = responseGroup[`${tenant}::${scratchProjectCode}::screen`]?.map(
          screen => screen.monitorable[0]
        )
        keyByScreens = keyBy(screens, '_id')
      }

      // the returned data has some calculated values.
      // the calculated values change depending on whether lockdown features
      // are enabled or not.
      const lockdownEnabled = checkFeature('safety screens.lockdown_features')

      const zonesWithScreens = zones.map((zoneObject) => {
        const zone = zoneObject.monitorable[0]
        zone.meta.id = zone.externalID
        if (zone.slots.screens && screens) {
          const screensArray = zone.slots.screens.map((screenID) => {
            if (screenID) {
              return {
                ...keyByScreens[screenID],
                zone: {
                  ...zone.meta,
                  updated: zone.updated,
                },
              }
            }
            return null
          })
          if (screensArray.filter(Boolean).length > 0) screenWithZone.push(screensArray)

          // calcualte screen alert level and set it as alertLevel on each screen.
          screenWithZone = flatten(screenWithZone).map((screen: ScreenProp) => {
            return {
              ...screen,
              alertLevel: Zones.zoneHelper.getDigitalTwinScreenStatusFromZone(
                screen,
                lockdownEnabled
              ),
            }
          })
        }

        // calcualte twinDecorationState and set it on each zone.
        return {
          ...zone.meta,
          twinDecorationState: Zones.zoneHelper.getDigitalTwinStatusFromZone(
            zone.meta,
            Zones.zoneHelper.isLockedDown(zone.meta)
          ),
        }
      })

      return { screens: screenWithZone, zones: keyBy(zonesWithScreens, 'id') }
    } catch (err) {
      return err
    }
  }

  /**
   * Performs a zone booking in the database.
   * @param {Zone} zone
   * @param {any} bookingInfo: From the zone booking form.
   * @return {Promise<Zone>}
   */
  static async bookZone(zone: Zone, bookingInfo: any): Promise<Zone> {
    const bootstrap = Zones.useClient.cache.current.bootstrap
    const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
    await Zones.useClient.server.post(
      `/scratch/telemetry/event`,
      {
        tenant,
        project: scratchProjectCode,
        source: 'webhq',
        type: 2,
        data: {
          action: 'zone_book',
          zoneId: zone.id,
          booking: bookingInfo,
        },
      },
      {
        timeout: 15000,
      }
    )
    return {
      ...zone,
      available: false,
      jumping: !!bookingInfo.isForJump,
      booking: bookingInfo,
    }
  }

  /**
   * Returns an already booked zone in the database.
   * @param {Zone} zone
   * @return {Promise<Zone>}
   */
  static async returnZone(zone: Zone, userEmail: string): Promise<Zone> {
    const bootstrap = Zones.useClient.cache.current.bootstrap
    const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
    await Zones.useClient.server.post(
      `/scratch/telemetry/event`,
      {
        tenant,
        project: scratchProjectCode,
        source: 'webhq',
        type: 2,
        data: {
          action: 'zone_return',
          zoneId: zone.id,
          reference: zone?.booking?.reference,
          returnedBy: userEmail,
        },
      },
      {
        timeout: 15000,
      }
    )
    return {
      ...zone,
      available: true,
      jumping: false,
      booking: undefined,
    }
  }
}
