const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  SEND_PASSWORD_RESET_EMAIL: 'user/SEND_PASSWORD_RESET_EMAIL',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  SWITCH_PROJECT: 'user/SWITCH_PROJECT',
  LOGOUT: 'user/LOGOUT',
}

export default actions
