import qs from 'qs'

/**
 * Iterates through an array of objects and returns a single object keyed by the
 * specified property.
 * @param {Array<any>} collection: Eg - [{name: 'Sarah', age: 27}, {name: 'Igor', age: 14}]
 * @param {string} property: A unique filed, eg 'name'.
 * @return {any} Eg - {'Sarah': {name: 'Sarah', age: 27}, 'Igor': {name: 'Igor', age: 14}}
 */
export const keyBy = (collection: Array<any>, property: string): any => {
  return collection.reduce(
    (items: any, item: any) => ({
      ...items,
      [item[property]]: item,
    }),
    {}
  )
}

/**
 * Truncates a string to a specified length. Eg 'Hello, world' => 'Hello...'
 * @param {string} str: The original full-length string.
 * @param {number} maxLength: How long can the string be before it gets cut off?
 * @param {string} ellipsis: Optional (defaults to '...').
 * @return {string} Either the original string, or a truncated string with an ellipsis.
 */
export const truncateString = (str: string, maxLength: number, ellipsis: string = '...'): string =>
  str.length <= maxLength ? str : `${str.substr(0, maxLength)}${ellipsis}`

/**
 * Parses a query string (e.g. '?foo=bar&hello=world') into a plain JavaScript object.
 * @param {string?} str
 * @return {object}
 */
export const parseQueryString = (str?: string): { [x: string]: any } => {
  if (!str) {
    return {}
  }
  let modifiedStr = str
  if (str.startsWith('?')) {
    modifiedStr = str.substr(1)
  }
  return qs.parse(modifiedStr)
}

export const findKeyWithMatchingValue = (obj: object, value: string) => {
  return Object.keys(obj)[Object.values(obj).indexOf(value)]
}

export const toTitleCase = (string: string) => {
  if (!string) return
  return string.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const toZoneFormat = (string: string) => {
  if (!string) return
  return string.replace('_', '-').toUpperCase()
}

/**
 * Rounds off a float number (e.g. '12345.6789') according to the precision.
 * roundValue(12345.6789, 2) // 12345.68
 * roundValue(12345.6789, 1) // 12345.7
 * roundValue(12345.6789) // 12346  - Default precision is 0
 * @param {number} value
 * @param {number?} precision
 * @return {number}
 */
export const roundValue = (value: number, precision?: number) => {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

/**
 *
 * This function receive arrays of arays of layer, then sort the array with longest number of array
 * is descending order
 * @param arrays [[{name: 'Layer 6'}], [{name: 'Layer 4'}, {name: 'Layer 5'}], [{name: 'Layer 1'},
 * {name: 'Layer 2'}, {name: 'Layer 3'}]]
 *
 * first sorts in the following format
 * [ [{name: 'Layer 1'}, {name: 'Layer 2'}, {name: 'Layer 3'}], [{name: 'Layer 4'},
 * {name: 'Layer 5'}], [{name: 'Layer 6'}]]
 * then returns output as the first from the above
 * [{name: 'Layer 1'}, {name: 'Layer 2'}, {name: 'Layer 3'}]
 */
export const selectLongestArrayOfArrays = (arrays = []): Array<any> => {
  if (arrays.length === 0) return []
  if (arrays.length === 1) return arrays[0]

  arrays.sort((a: any, b: any) => b.length - a.length)

  return arrays[0]
}
