import actions from './actions'

const initialState = {
  data: [],
  uniqueOccupants: [],
  uniqueZones: [],
  isFetching: false,
}

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_ZONES_HISTORY:
      return {
        ...state,
        isFetching: true,
      }
    case actions.FETCH_ALL_ZONES_HISTORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.zones,
        uniqueOccupants: action.payload.uniqueOccupants,
        uniqueZones: action.payload.uniqueZones,
      }
    default:
      return state
  }
}
