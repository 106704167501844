import actions from './actions'

const initialState = {
  lastChecked: new Date(),
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CHECK_FOR_UPDATE:
      return state
    default:
      return state
  }
}

export const getLastChecked = state => state.lastChecked
