/* irrespective of the environment, the following configuration remains constant */
import icons from './icons.json'
import routes from './routes.json'
import color from './color.json'
import status from './status.json'

export { icons, routes, color, status }

/**
 * @ynomia/client configuration constants.
 */
export const YNOMIA_CLIENT_SDK_NAME = 'webhq'
export const ACCESS_TOKEN_STORAGE_KEY = 'access_token'
export const ID_TOKEN_STORAGE_KEY = 'id_token'
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
export const PROJECT_ID_STORAGE_KEY = 'project_id'

/**
 * The following project codes have live data available.
 */
export const LIVE_DATA_PROJECT_CODES = [
  '64364',
  '27764',
  '32798',
  '49781',
  '19178',
  '18660',
  '122735',
  '783838',
  '146981',
  '013983',
  '125873',
  '969845',
]

/**
 * How regularly we confirm the connectivity status of the current user with our backend.
 */
export const HEARTBEAT_INTERVAL = 120000

/**
 * Interval for how regularly we need to check for updates to WebHQ.
 * If an update is needed, the website will refresh.
 */
export const WEBHQ_AUTO_REFRESH_CHECK_INTERVAL = 60 * 60 * 1000

/**
 * Periodic data fetching intervals.
 */
export const DEFAULT_FETCH_INTERVAL = 30000
