import client from '../Client'
import { history } from '../../redux/store'
import { HEARTBEAT_INTERVAL } from '../../config/constants'

export default class Heartbeat {
  /**
   * Sends a heartbeat to the server to be forwarded to datadog
   *
   * @return {Promise<void>}
   */
  static async sendHeartbeat(): Promise<void> {
    const { bootstrap, auth } = client.cache.current

    // Don't send a heartbeat if the user is not authenticated
    if (!auth.isAuthenticated) {
      return
    }

    const projectCode = bootstrap?.project?.metadata.projectCode
    const { pathname } = history.location

    try {
      await client.server.post(
        `/scratch/metrics/heartbeat`,
        {
          data: {
            pathname,
            projectId: projectCode,
          },
        },
        {
          timeout: HEARTBEAT_INTERVAL,
        }
      )
    } catch {
      console.warn('Warning: Failed to send heartbeat.')
    }
    return
  }
}
