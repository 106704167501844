import client from '../Client'
import { keyBy } from '../../utils'
import { RawPoursMap, PoursMap } from '../../config/interfaces'
import { TelemetryAssetProps } from 'pages/dashboard/facade/status/helper/interface'

interface PourResponseMap {
  raw: RawPoursMap
  pours: PoursMap
}

/**
 * This service manages the management of Pours.
 */
export default class Pours {
  /**
   * Fetches the entire list of Pours from the backend. Note that
   * @param {string} projectCode: Unique project code to fetch the pours for.
   * @return {Promise<PourResponseMap>}
   */

  static async fetchAll(projectCode: string): Promise<PourResponseMap> {
    const bootstrap = client.cache.current.bootstrap
    const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
    const response = await client.server.get(
      `/scratch/telemetry/all/${tenant}/${scratchProjectCode}/pour`,
      {
        timeout: 10000,
      }
    )
    const pours = response.data.map(p => p.meta)
    return {
      raw: (response.data as unknown) as RawPoursMap, // work around for #173682783
      pours: keyBy(pours, 'name'),
    }
  }

  /**
   * Performs a pourupdate in the database.
   * @param {Pour} pour
   * @return {Promise<Pour>}
   */
  static async updatePour(pour: TelemetryAssetProps): Promise<TelemetryAssetProps> {
    const bootstrap = client.cache.current.bootstrap
    const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
    await client.server.post(
      `/scratch/telemetry/event`,
      {
        tenant,
        project: scratchProjectCode,
        source: 'webhq',
        type: 2,
        data: {
          action: "pour_upsert",
          externalId: pour.externalID,
          attributes: {
            plannedDate: pour.plannedPourDate,
            interruptions: pour.interruptions,
          }
        },
      },
      {
        timeout: 15000,
      }
    )
    
    return {
      ...pour
    }
  }
}
