import store from 'store'
import actions from './actions'

const STORED_SETTINGS = (storedSettings) => {
  const settings = {}
  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    isMenuShadow: false,
    isMenuUnfixed: false,
    menuLayoutType: 'left', // left, top, top-dark, nomenu
    menuType: 'default', // default, flyout, compact
    menuColor: 'dark', // dark, blue, gray, white
    flyoutMenuColor: 'blue', // dark, blue, gray, white
    systemLayoutColor: 'gray', // white, dark, blue, gray, image
    isTopbarFixed: false,
    isFooterDark: false,
    isContentNoMaxWidth: true,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isGrayTopbar: false,
    isCardShadow: false,
    isSquaredBorders: true,
    isBorderless: false,
    routerAnimation: 'fadein', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
    enableSidebar: true,
    showDemoContent: true,
    demoMode: 'minimal',
    year: new Date().getFullYear(),
    statusProgressMode: 'bar',
    authVersion: localStorage.getItem('authVersion') || 0,
    presenterMode: false,
    structureBetaMode: false,
  }),
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export const getSettings = state => state.settings
