/**
 * This file contains generic, shareable TypeScript interfaces that used in multiple
 * locations of the project.
 *
 * @todo Some of these interfaces will need to be moved to the shared library called
 * `@ynomia/core` when it becomes available. That will mostly include interfaces which
 * appear to be "models" for a server-side resources (eg: Zones, Assets etc).
 */

import { Layer as BPLayer, LayerType as BPLayerType } from '@ynomia/core/dist/blueprint'

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P]
}

export interface AntDesignTableConfigBase {
  key?: string
  [x: string]: any
}

export interface AntDesignTableConfig extends AntDesignTableConfigBase {
  children?: Array<AntDesignTableConfigBase>
}

export interface ZoneBooking {
  contractor: string
  contractorWasFreeText: boolean
  isForJump: boolean
  endTime: string
  approver: string
  mobile: string
  fullName: string
  reference?: string
}

export interface Zone {
  id: string
  externalID: string
  created: string
  meta: ZoneMeta
  slots: any
  updated: string
  assetType: string
  [x: string]: any
}

export interface ZoneMeta {
  id?: string
  name: string
  order: number
  available: boolean
  available_updated?: string
  jumping: boolean
  jumping_timestamp?: string
  lockedDown: boolean
  booking?: ZoneBooking
  edge_status: EdgeScreenStatus
  edge_status_updated: string
  updated?: string
  twinDecorationState?: string
}

export interface ZonesMap {
  [id: string]: Zone
}

export interface ZonesBootstrap {
  booking_form_config: any
  return_form_config: any
}

export interface ZoneState {
  isFetching: boolean
  lastFetchFailed: boolean
  lastFetchSuccessTime: Date
  firstFetchFailureTime: Date
  data: ZoneMeta[]
  screens: ScreenProp[]
}

export interface StructuresBootstrap {
  pours: {
    edit_form_config: any
  }
}

export interface Pour {
  id: string
  name: string
  order: number
}

export interface PoursMap {
  [id: string]: Pour
}

interface MonitorableReading {
  [id: string]: any
}

export interface Monitorable {
  externalID: string
  assetType: string
  created: Date
  meta: { name: string; mac: string }
  readings: Array<MonitorableReading>
  slots: Array<any>
}

export interface RawPoursMap {
  [id: string]: Monitorable
}

export interface AssetSummaryBasicProp {
  _id?: string
  id?: string
  date_status_achieved: string
  level: string
  name: string
  status: string
  type: string
  user: string
  meta?: any
}

interface ProjectMetaData {
  client: string
  id: string
  name: string
  projectCode: string
  projectCodeAlt?: string
  scratchProjectCode: string
  version: string
  [x: string]: any
}

interface ProjectCalendar {
  projectStart: string
  projectEnd: string
  [x: string]: any
}

export interface Project {
  metadata: ProjectMetaData
  menu: Array<any>
  asset_types: {
    historyRetention?: number
    [x: string]: any
  }
  zones: any
  predefined_assets: any
  predefined_devices: any
  models: {
    version: string
    default: {
      id: string
    }
  }
  calendar: ProjectCalendar
  ids: ID[]
  [x: string]: any
}

export interface ReportProp {
  meta: {}
  values: {}
  original: {}
  filteredList: {}
}
export interface UserProp {
  liveData: boolean
  client: string
  project: Project
  projects: Array<Project>
  auth: any
  avatar: string
  email: string
  id: string
  isSwitchingProjects: boolean
  loading: boolean
  passwordResetLoading: boolean
  passwordResetSent: boolean
  name: string
  role: string
  selectedProjectId: string
}

export enum NotificationType {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface ScreenProp {
  externalID: string
  created: string
  meta: {
    name: string
    screen_status: EdgeScreenStatus
    screen_status_updated_at: string
    hatches_status: HatchesStatus | null
    hatches_status_updated_at: string | null
  }
  zone?: ZoneMeta
  alertLevel?: string
}

export enum EdgeScreenStatus {
  live = 'live',
  secured = 'secured',
}

export enum HatchesStatus {
  open = 'open',
  close = 'close',
}

export type GeoJSON = {
  lon: string | number
  lat: string | number
  alt?: string | number
}

export interface TrackableProp {
  id: string
  label: string
  assetType: string
  lon?: string
  lat?: string
  alt?: string
  updated: string
  created: string
  lastHeard?: string
  layers?: string[]
  layerObjects?: Layerv2[] | Layerv2[][]
  type?: string
  rtLayers?: string[]
  zPlane?: number
}

export interface TrackableSearchObject {
  _id: string
  label: string
  updated: string
  created: string
  trackable: {
    tracking: TrackableLocationEvent[]
    history: TrackableLocationEvent[]
  }
}

interface TrackableLocationEvent {
  assetType: string
  lon: string
  lat: string
  alt: string
  timestamp: string
}

export type Point = {
  geoJSON?: GeoJSON
  icon?: number
  id?: string
  key?: string
  name?: string
  status?: string
  timer?: string
  timestamp?: Date
}

export interface Layerv2 extends BPLayer {
  label: string
  created?: Date
}

export interface EnrichedLayers extends Layerv2 {
  parent: Layerv2 | undefined
  siblings: Layerv2[] | undefined
  ancestors: Layerv2[] | []
  directChild: Layerv2[] | []
  rootLayer: Layerv2[]
}

export interface DecoratedLayers {
  primaryLayers: { [x: string]: Layerv2 }
  enrichedLayers?: EnrichedLayers[]
  isMultiLevel: boolean
}

export interface LayerType extends Partial<BPLayerType> {
  name?: string
  label?: string
}

export type ID = {
  id: string
  label: string
  type: string
  [x: string]: any
}

export type IdsIndex = {
  [x: string]: ID | Layerv2
}

export interface AssetDataWithLocation {
  id: string
  key?: string
  contains: string[]
  idsData: IdsDataProp
  trackableData?: TrackableProp
  observationData?: ObservationProp
  monitorableData?: any //TODO : Will be added when we have clearity on how monitorable looks like
}

export interface ObservationProp {
  statusChanges: {
    [x: string]: ObservationStatusChanges
  }
  notes: ObservationNotes[]
}

export type ObservationStatusChanges = {
  date: string
  time: string
  dateTime: string
  type: string
  userID: string
}

// TODO Fix once we have the scratch implementation
export type ObservationNotes = {
  timestamp?: string
  assetType?: string
  assetName?: string
  fields?: {
    [x: string]: string
  }
  layerID?: string
  layerName?: string
  statusID?: string
  statusName?: string
  userID?: string
  userName?: string
}

export interface IdsDataProp {
  id: string
  label: string
  meta?: any
  type: string
  updatedAt: string
  createdAt: string
  convertedId: string
  statusID?: string
  statusLabel?: string
  twinID?: string[]
  destinationLayerID?: string
  destinationLayerLabel?: string
}

export interface SearchApiAssetDataProp {
  _id: string
  label: string
  meta: null
  type: string
  status: string
  updated: string
  created: string
  destination?: { layerID: string }
  isArchived: boolean
  convertedId: string
  trackable?: { tracking?: any[]; history?: any[] }
}

export interface TwinRTVFactory {
  geoJSON: GeoJSON
  icon: number
  id: string
  name: string
}

export type DynamicFormStructure = {
  entries: any[]
  buttons?: any[]
}

export interface Template {
  id: string
  label: string
  destination: boolean
}
