import { icons, routes, color, status } from './constants'
import { Environment } from '@ynomia/client'

import development from './development.json'
import staging from './staging.json'
import staging_uk from './staging-uk.json'
import production from './production.json'
import production_uk from './production-uk.json'

const hostConfigs = {
  development,
  staging,
  staging_uk,
  production,
  production_uk,
}

export const detectEnv = function (hostname: string, fallback?: Environment): Environment {
  // point preview environments to staging_uk envirnoment
  if (hostname.includes('azurestaticapps.net')) return 'staging_uk' as Environment

  switch (hostname) {
    case 'ynomia.com':
      return 'production'
    case 'ynomia.co.uk':
      return 'production_uk' as Environment
    case 'v1.ynomia.com':
      return 'production'
    case 'v1.ynomia.co.uk':
      return 'production_uk' as Environment
    case 'stage.ynomia.com':
      return 'staging'
    case 'stage.ynomia.co.uk':
      return 'staging_uk' as Environment
    default:
      return fallback || 'development'
  }
}

// obtain the specific environment config
const API_ENV = process.env.REACT_APP_API_ENV
let config: any = hostConfigs[detectEnv(window.location.hostname, API_ENV as Environment)]

// combine with the constants (allowing environment to override constants)
config = {
  icons,
  routes,
  color,
  status,
  ...config,
}

export default config