import { all, takeEvery } from 'redux-saga/effects'
import heartbeatActions from './actions'
import HeartbeatService from '../../services/Heartbeat'

export function* CREATE_HEARTBEAT() {
  yield HeartbeatService.sendHeartbeat()
}

export default function* rootSaga() {
  yield all([takeEvery(heartbeatActions.CREATE_HEARTBEAT, CREATE_HEARTBEAT)])
}
