import { Bootstrap } from '@ynomia/core/dist/blueprint'
import { Response } from '@ynomia/client'
import client from './Client'
import { PROJECT_ID_STORAGE_KEY } from '../config/constants'

export async function login(
  email: string,
  password: string,
  staySignedIn: boolean
): Promise<boolean> {
  const lastProjectId = localStorage.getItem(PROJECT_ID_STORAGE_KEY)
  return client.login(email, password, staySignedIn, lastProjectId)
}

export async function bootstrap(projectId?: string): Promise<Bootstrap> {
  const localStorageProjectId = localStorage.getItem(PROJECT_ID_STORAGE_KEY)
  return client.server.bootstrap(projectId || localStorageProjectId)
}

export async function logout() {
  client.logout()
}

export function sendResetPasswordEmail(email: string): Promise<Response> {
  return client.server.post('/scratch/auth/reset-password', { email })
}
