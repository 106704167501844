import ynomiaclient from '../Client'
import { GeoJSON } from 'config/interfaces'

interface LayerMap {
  [x: string]: any
}

export interface deleteBeaconLayerServiceProp {
  layerId: string
  beaconId: string
}

interface beaconServicePayloadProp {
  layerId: string
  geoJSON: GeoJSON
}

export interface addBeaconLayerServiceProp extends beaconServicePayloadProp {
  beaconTempID: string
}

export interface updateBeaconLayerServiceProp extends beaconServicePayloadProp {
  beaconId: string
}

export default class Layers {
  static useClient: any

  static async fetchAll(client?: any): Promise<LayerMap> {
    Layers.useClient = client ? client : ynomiaclient
    try {
      const bootstrap = Layers.useClient.cache.current.bootstrap

      const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata

      const response = await Layers.useClient.server.get(
        `/scratch/project/${tenant}/${scratchProjectCode}/layers`,
        {
          timeout: 10000,
        }
      )
      return response.data
    } catch (err) {
      // TODO: handling API call failure
      return err
    }
  }

  static async addLayers(payload: any, client?: any): Promise<any> {
    Layers.useClient = client ? client : ynomiaclient
    let bulkResponse = []
    try {
      const entries = Object.entries(payload)
      try {
        for (const entry in entries) {
          const [, layerPayload] = entries[entry]
          const response = await Layers.addLayer(layerPayload, client)
          bulkResponse.push(response)
        }
      } catch (err) {
        console.error('addLayers inner failed', err)
        bulkResponse.push(null)
      }

      if (bulkResponse.length === Object.entries(payload).length)
        return { value: bulkResponse, err: null }
      else {
        // don't return when we dont receive all response
      }
    } catch (err) {
      // TODO: handling API call failure
      console.error('addLayers failed', err)
    }
  }

  static async addLayer(payload: any, client?: any): Promise<any> {
    Layers.useClient = client ? client : ynomiaclient
    try {
      const bootstrap = Layers.useClient.cache.current.bootstrap

      const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata

      const response = await Layers.useClient.server.post(
        `/scratch/project/${tenant}/${scratchProjectCode}/layer`,
        {
          ...payload,
          type: `${tenant}::${scratchProjectCode}::${payload.type}`,
        },
        {
          timeout: 10000,
        }
      )
      return response.data.value
    } catch (err) {
      // TODO: handling API call failure
      console.error('addLayer failed', err)
    }
  }

  static async addBeacon(
    payload: addBeaconLayerServiceProp,
    client?: any
  ): Promise<{ raw: any; tempId: string }> {
    Layers.useClient = client ? client : ynomiaclient
    try {
      const bootstrap = Layers.useClient.cache.current.bootstrap
      const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
      const response = await Layers.useClient.server.post(
        `/scratch/project/${tenant}/${scratchProjectCode}/layer/${payload.layerId}/beacon`,
        {
          geoJSON: payload.geoJSON,
        },
        {
          timeout: 10000,
        }
      )

      return {
        raw: response.data.value,
        tempId: payload.beaconTempID,
      }
    } catch (err) {
      // TODO: handling API call failure
    }
  }

  static async updateBeacon(
    payload: updateBeaconLayerServiceProp,
    client?: any
  ): Promise<{ raw: any }> {
    try {
      Layers.useClient = client ? client : ynomiaclient
      const bootstrap = Layers.useClient.cache.current.bootstrap
      const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
      const response = await Layers.useClient.server.put(
        `/scratch/project/${tenant}/${scratchProjectCode}/layer/${payload.layerId}/beacon/${payload.beaconId}`,
        {
          geoJSON: payload.geoJSON,
        },
        {
          timeout: 10000,
        }
      )
      return {
        raw: response.data.value,
      }
    } catch (err) {
      // TODO: handling API call failure
    }
  }

  static async deleteBeacon(payload: deleteBeaconLayerServiceProp, client?: any) {
    try {
      Layers.useClient = client ? client : ynomiaclient
      const bootstrap = Layers.useClient.cache.current.bootstrap
      const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
      const response = await Layers.useClient.server.delete(
        `/scratch/project/${tenant}/${scratchProjectCode}/layer/${payload.layerId}/beacon/${payload.beaconId}`,
        {
          timeout: 10000,
        }
      )
      return response.data
    } catch (err) {
      // TODO: handling API call failure
    }
  }
}
