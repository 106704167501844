import { InMemoryCache, Server, SessionManager } from '@ynomia/client'
import cacheObserver from './CacheObserver'
import authObserver from './AuthObserver'
import config, { detectEnv } from '../../config'
import {
  YNOMIA_CLIENT_SDK_NAME,
  ACCESS_TOKEN_STORAGE_KEY,
  ID_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  PROJECT_ID_STORAGE_KEY,
} from '../../config/constants'
import { store } from '../../redux/store'
import userActions from '../../redux/user/actions'

/**
 * This service abstracts the private npm package, '@ynomia/client'. For further
 * documentation, please visit: https://www.npmjs.com/package/@ynomia/client
 *
 * You use this library to make API requests to the core Ynomia backend services.
 */
export default class YnomiaClient {
  private clientCache: InMemoryCache
  private clientSession: SessionManager
  private clientServer: Server

  constructor() {
    this.clientCache = new InMemoryCache()
    this.clientSession = new SessionManager(this.clientCache)
    this.clientServer = new Server(this.session, this.clientCache)
  }

  /**
   * Initializes the Ynomia Client with a fresh state. This method should be called
   * shortly after the web application first starts up.
   * @return {void}
   */
  initialize(): void {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
    const idToken = localStorage.getItem(ID_TOKEN_STORAGE_KEY)
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)
    const projectId = localStorage.getItem(PROJECT_ID_STORAGE_KEY)
    this.clientCache = new InMemoryCache({
      clientName: YNOMIA_CLIENT_SDK_NAME,
      platform: 'web',
      environment: detectEnv(window.location.hostname),
      baseURL: config.host.api,
      auth: {
        accessToken,
        idToken,
        refreshToken,
      },
    })
    this.clientSession = new SessionManager(this.clientCache)
    this.clientServer = new Server(this.session, this.clientCache)

    // Subscribe our own event listeners to Client SDK Observables
    cacheObserver.initialize({ onChange$: this.clientCache.onChange$ })
    authObserver.initialize({
      onEstablish$: this.clientSession.onEstablish$,
      onRefresh$: this.clientSession.onRefresh$,
      onTerminate$: this.clientSession.onTerminate$,
    })

    // Is the user potentially still authenticated in from their previous visit?
    if (this.clientCache.current.auth.isAuthenticated) {
      store.dispatch({
        type: userActions.LOAD_CURRENT_ACCOUNT,
        payload: { projectId },
      })
    }
  }

  /**
   * @return {InMemoryCache}
   */
  get cache(): InMemoryCache {
    return this.clientCache
  }

  /**
   * @return {SessionManager}
   */
  get session(): SessionManager {
    return this.clientSession
  }

  /**
   * @return {Server}
   */
  get server(): Server {
    return this.clientServer
  }

  /**
   * Attempts to login a user using their email and password.
   * @param {string} email
   * @param {string} password
   * @param {boolean} staySignedIn: Ask the backend to issue us with a `refresh_token`.
   * @param {string?} projectId: Determines which project to bootstrap by default.
   * @return {Promise<boolean>}
   */
  async login(
    email: string,
    password: string,
    staySignedIn: boolean,
    projectId?: string
  ): Promise<boolean> {
    try {
      await this.clientSession.authenticate({
        loginWithBootstrap: () =>
          this.clientSession.simpleLogin(email, password, {
            bootstrap: true,
            staySignedIn,
            projectId,
          }),
      })
      return true
    } catch {
      return false
    }
  }

  /**
   * Clears an existing user's session from local storage and the application memory.
   * @return {Promise<void>}
   */
  async logout(): Promise<void> {
    this.clientSession.destroy('user_initiated')
  }
}
