import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import config from './config'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'
import { store, history } from './redux/store'
import client from './services/Client'
import * as Sentry from '@sentry/react'

// app styles
import './global.scss'

// Initialise the Ynomia Client SDK on first load
client.initialize()

/**
 * Initialise Google Analytics & Page Tracking
 */
ReactGA.initialize(config.analytics.id, { testMode: !config.analytics.enabled })
history.listen((location) => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

// Initialise sentry logging
if (process.env.REACT_APP_SENTRY_LOGGING?.toLowerCase() !== 'off') {
  Sentry.init({ dsn: config.sentryLogging.dsn })
}

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
