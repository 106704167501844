import axios from 'axios'
import moment from 'moment'

/**
 * This service manages the fetch of Timelapse Photos.
 */
export default class Snaps {
  /**
   * Fetches the entire list of Photos from the backend. Note that
   * @param {string} projectCode: Unique project code to fetch the photos for.
   * @return {Promise<Photos>}
   */

  static async fetchAll(projectCode: string): Promise<any> {
    const url = `https://simplescraper.io/api/AKHgrfRLnzKiRZb3Jqd9?apikey=BlliGE1E8GDxdY8AbYOrOlrN6ksw2Cjf&offset=0&limit=20`
    const response = await axios.get(url)
    const data = response?.data?.data || []
    const formattedData = data.map(formatPhotoResult)
    return formattedData.sort((a, b) => b.timestamp - a.timestamp)
  }
}

function formatPhotoResult(obj) {
  return {
    image_url: formatUrl(obj.image_url_link),
    thumbnail_url: formatUrlForThumbnail(formatUrl(obj.image_url_link)),
    timestamp: extractDate(obj.image_url_link, 'unix'),
    date: extractDate(obj.image_url_link, 'formatted'),
    source: obj.url,
  }
}

function formatUrl(url) {
  if (url) {
    return url.replace('http://', 'https://')
  }
}

function extractDate(url, type = 'formatted') {
  // date
  const s: string = url.split('_')[2]
  const year = s.slice(0, 4)
  const month = s.slice(4, 6)
  const day = s.slice(6, 8)
  const date = `${year}/${month}/${day}`
  // time
  const hour = url.split('_')[3]
  const min = url.split('_')[4]
  const sec = url.split('_')[5]
  const time = `${hour}:${min}:${sec}`

  // out
  return type === 'unix' ? moment(`${date} ${time}`).format('x') : `${date} ${time}`
}

function formatUrlForThumbnail(url) {
  return url.replace('preview', 'thumb')
}
