import config from '../config'

// accepts:
// - projectKey (string)
// - options.blacklist (string) (optional)
// - options.whitelist (string) (optional)
// returns a projects list of status, excluding blacklisted keys.
export const getStatiForProject = function (projectKey, options = {}) {
  if (!projectKey) {
    return []
  }
  try {
    if (options.blacklist) {
      return config.status[projectKey].all.filter(item => !options.blacklist.includes(item))
    }

    if (options.whitelist) {
      return config.status[projectKey].all.filter(item => options.whitelist.includes(item))
    }

    return config.status[projectKey].all
  } catch (err) {
    console.error('getStatusExcluding failed', err)
    return []
  }
}
