export interface TimeControlsState {
  selectedTimeMode: string
  selectedDateObject: any
  selectedDateTextValue: string | string[]
}

export enum TimeControlsMode {
  LIVE = 'live',
  DAY = 'day',
  WEEK = 'week',
  DATE_RANGE = 'date range',
  POINT_IN_TIME = 'point in time',
}

export interface DateFilter {
  startDate?: moment.Moment | null
  endDate?: moment.Moment | null
  mode?: TimeControlsMode
  timezone?: string
}
