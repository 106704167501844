import { store } from '../../redux/store'

/**
 * A simple class which calls redux dispatch actions at desired intervals. This is useful
 * for organising actions which need to trigger periodic fetching or sending of data.
 */
export default class DispatchScheduler {
  actions: { [type: string]: NodeJS.Timeout } = {}

  /**
   * Adds an action to the jobs list. Any existing schedule for the specified key will be
   * cleared before the new one is added.
   * @param {string} type: The action name to dispatch.
   * @param {number} interval: How often the action should be dispatched in milliseconds.
   * @param {boolean} forceRestart: Forces the action to restart if it's already scheduled.
   * @return {void}
   */
  start(type: string, interval: number, forceRestart: boolean = false): void {
    if (this.actions[type] !== undefined && !forceRestart) return
    this.cancel(type)
    this.actions = {
      ...this.actions,
      [type]: setInterval(() => store.dispatch({ type }), interval),
    }
    store.dispatch({ type })
  }

  /**
   * Adds an action to the jobs list. Takes in ctx as an additional arguements.
   * Any existing schedule for the specified key will be
   * cleared before the new one is added.
   * @param {string} type: The action name to dispatch.
   * @param {[x:string]: any} ctx: The context needs to be pass in for dispatch
   * @param {number} interval: How often the action should be dispatched in milliseconds.
   * @param {boolean} forceRestart: Forces the action to restart if it's already scheduled.
   * @return {void}
   */
  startWithContext(
    type: string,
    payload: { [x: string]: any } = {},
    interval: number,
    forceRestart: boolean = false
  ): void {
    if (this.actions[type] !== undefined && !forceRestart) return
    this.cancel(type)

    this.actions = {
      ...this.actions,
      [type]: setInterval(() => store.dispatch({ type, payload }), interval),
    }
    store.dispatch({ type, payload })
  }

  /**
   * Stops a scheduled action from running any further.
   * @param {string} type
   * @return {void}
   */
  cancel(type: string): void {
    if (this.actions[type] !== undefined) {
      try {
        clearInterval(this.actions[type])
        delete this.actions[type]
      } catch {
        // Ignore
      }
    }
  }

  /**
   * Completely stops and clears all stored dispatch actions.
   * @return {void}
   */
  clearAll(): void {
    Object.keys(this.actions).forEach(type => this.cancel(type))
  }
}
