import actions from './actions'

const initialState = {
  data: {},
  isFetching: false,
  lastFetchFailed: false,
  firstFetchFailureTime: undefined,
  isSending: false,
  lastFetchStartTime: undefined,
  lastFetchSuccessTime: undefined,
  selections: [],
}

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_ZONES:
      return {
        ...state,
        isFetching: true,
        lastFetchStartTime: new Date(),
      }
    case actions.FETCH_ALL_ZONES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastFetchFailed: false,
        lastFetchSuccessTime: new Date(),
        firstFetchFailureTime: undefined,
        data: action.payload.zones,
        screens: action.payload.screens,
      }
    case actions.FETCH_ALL_ZONES_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastFetchFailed: true,
        firstFetchFailureTime: state.firstFetchFailureTime || new Date(),
      }
    case actions.SELECT_ZONE:
      const existingSelection = state.selections.find(zoneId => zoneId === action.payload)
      if (existingSelection) return state
      return {
        ...state,
        selections: [...state.selections, action.payload].sort(),
      }
    case actions.DESELECT_ZONE:
      return {
        ...state,
        selections: state.selections.filter(zoneId => zoneId !== action.payload),
      }
    case actions.DESELECT_ALL_ZONES:
      return { ...state, selections: [] }
    case actions.ZONES_ARE_SENDING:
      return { ...state, isSending: action.payload }
    case actions.UPDATE_ZONE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      }
    default:
      return state
  }
}
