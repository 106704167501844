import config from '../config'
import client from './Client'
import { MenuItem } from '@ynomia/core/dist/blueprint'
import { findKeyWithMatchingValue } from 'utils/processors'

/**
 * Tests if a route is valid
 * @param routeKey The key of the route
 * @param features Features from boostrap
 */
function validRoute(routeKey: string, menu: MenuItem[]) {
  return !!menu.find(menuItem => menuItem.name === routeKey)
}

/**
 * Returns the first valid route in existing routes.
 * @param routeKeys Array of routes
 * @param features Features from boostrap
 * @param routes Mapping of routes with route keys
 */
export function getFirstValidRoute(
  routeKeys: string[],
  menu: MenuItem[],
  routes: { [key: string]: string }
) {
  for (const key of routeKeys) {
    // If the predefined route is valid, then return the route
    if (validRoute(key, menu)) {
      return routes[key]
    }
  }
  return undefined
}

/**
 * Fetches the default route by checking the below:
 *  1) First checks if any of the predefined routes are valid
 *  2) Gets the first valid route in features
 *  3) Otherwise, kicks the user to the login screen
 * @param requestedRoute Returns requested route if valid
 * @param features Features from boostrap
 * @param routes Mapping between the route key and route
 */
export function getDefaultRouteFromProject(
  requestedRoute: string | undefined,
  menu: MenuItem[],
  routes: { [key: string]: string }
) {
  if (menu && routes) {

    if (requestedRoute?.startsWith("/files")) {
      return requestedRoute
    }

    const requestedRouteKey = findKeyWithMatchingValue(routes, requestedRoute)
    const defaultRoute = '/module/facade/status'
    const defaultRouteKey = findKeyWithMatchingValue(routes, defaultRoute)
    const envRouteKey = findKeyWithMatchingValue(routes, process.env.REACT_APP_INITIAL_ROUTE)

    // We test the route keys in a priority order
    // If the predefined keys fail, try the first keys provided by menu
    const routeKeys = [
      requestedRouteKey,
      envRouteKey,
      defaultRouteKey,
      ...menu.map(menuItem => menuItem.name),
    ]
    const result = getFirstValidRoute(routeKeys, menu, routes)

    if (result) {
      return result
    }
  }

  return '/system/login'
}

/**
 * Intelligently gets the default route. Will return the requested route if available.
 * @param requestedRoute A route that the user is requesting to access
 */
export function getDefaultRoute(requestedRoute: string) {
  const menu = client.cache.current.bootstrap?.project.menu
  return getDefaultRouteFromProject(requestedRoute, menu, config.routes)
}
