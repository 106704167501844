import actions from './actions'

const initialState = {
  heartbeatEnabled: true,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_HEARTBEAT:
      return state
    default:
      return state
  }
}
