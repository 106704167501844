import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import reducers from './reducers'
import sagas from './sagas'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middleware = [sagaMiddleware, routeMiddleware]

const getComposeFn = () => {
  if (process.env.NODE_ENV === 'development') {
    return composeWithDevTools as typeof compose
  }
  return compose
}

const store = createStore(reducers(history), getComposeFn()(applyMiddleware(...middleware)))
sagaMiddleware.run(sagas)

export { store, history }
