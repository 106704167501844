import * as _ from 'lodash'
import client from '../Client'

/**
 * Determines whether the provided feature is enabled or not.
 * Returning true when enabled; false when not enabled.
 *
 * Accepts a string or array of strings in dot notation,
 * allowing the check of infinite sub features.
 *
 * Eg. checkFeature("zones.booking.override") will check
 * that the override feature of booking of zones is enabled.
 * @param conditions
 */
export function checkFeature(conditions: string | string[]) {
  try {
    const { features } = client.cache.current.bootstrap?.project
    return checkFeatureFromBoostrap(conditions, features)
  } catch (err) {
    return false
  }
}

export function getFeatureFlagData(topLevelFeature: string, path: string): any {
  const { features } = client.cache.current.bootstrap?.project

  return _.get(features, `${topLevelFeature}.flags.${path}`)
}

export function checkFeatureFromBoostrap(conditions: string | string[], features: any) {
  let paths: string[]
  if (typeof conditions === 'string') {
    paths = [conditions]
  } else {
    paths = conditions
  }

  return paths.reduce((result, path) => {
    return result && checkOneFeatureFromBoostrap(path, features)
  }, true)
}

function checkOneFeatureFromBoostrap(path: string, features: any) {
  const route = path.split('.')
  const parent = features[route[0]]

  /**
   * When switching projects, the route and features
   * may not be in sync, resulting in an undefined parent.
   */
  if (!parent) {
    return false
  }

  const parentEnabled = parent.enabled

  // If route only contains the parent
  if (route.length === 1) {
    return parentEnabled
  }

  // Check for sub-features
  route.splice(1, 0, 'flags')
  route.push('enabled')

  // Sub-features are only enabled when the parent is
  if (!parentEnabled) {
    return false
  }

  // Check if the route is valid
  let result = features
  for (const next of route) {
    result = result[next]

    if (result === undefined) {
      return false
    }
  }

  return typeof result === 'boolean' && result
}
