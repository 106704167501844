import { omit, has, isEmpty, flatten } from 'lodash'
import moment from 'moment-business-days'
import { AssetSummaryBasicProp } from 'config/interfaces'
import { MixedPourProp } from '../FacadeStatusInterface'

export class DashboardStructureHelper {
  compileDataFromApis = (reportData, telemetryData, blueprintStructureData) => {
    return reportData.map((statusGroup) => {
      statusGroup['assets'] = statusGroup.assets.map((item) => {
        const foundTelemMatch = telemetryData?.find((data) => {
          return data.externalID === item._id
        })

        const foundBlueprintMatch = blueprintStructureData?.find((data) => {
          return data.name === item.name
        })

        let combinedData = Object.assign({ ...item, ...foundTelemMatch, ...foundBlueprintMatch })

        // manually assign key 'id' because hvmt data id is '_id' and will be omitted
        if (!has(combinedData)) combinedData['id'] = item._id

        return omit(combinedData, ['_id', 'externalID'])
      })
      return statusGroup
    })
  }

  calculateGrossCycle = (previousPourDate: moment.Moment, currentPourDate: moment.Moment) => {
    return currentPourDate.startOf('day').businessDiff(previousPourDate.startOf('day'))
  }

  calculateNetCycle = (grossCycle: number, interruptions: number | null = null): number => {
    if (!grossCycle || !interruptions) return null

    return grossCycle - interruptions
  }

  populatePourList = (filteredLevelData) => {
    if (isEmpty(filteredLevelData)) return []

    let pourList

    pourList = filteredLevelData.map(obj =>
      obj.count > 0 ? sortAssetPropBySingleField(obj.assets, 'date_status_achieved') : []
    )

    return flatten(pourList)
  }

  populatePourListWithAnalytics = (mixedPour) => {
    const result = this.populatePourList(mixedPour)

    return result.map((item: MixedPourProp) => {
      let findNextOfKin = null,
        grossCycleTime: number
      findNextOfKin = result.find(element => element.name === item.nextOfKin)
      if (findNextOfKin && findNextOfKin.date_status_achieved && isPourCompleted(item.status)) {
        grossCycleTime = this.calculateGrossCycle(
          moment(findNextOfKin.date_status_achieved),
          moment(item.date_status_achieved)
        )
      }

      const netCycleTime = this.calculateNetCycle(grossCycleTime, item?.meta?.interruptions)

      return {
        ...item,
        plannedDate: item?.meta?.plannedDate,
        grossCycleTime: grossCycleTime,
        date_status_achieved: isPourCompleted(item.status) ? item.date_status_achieved : null,
        netCycleTime: netCycleTime ? netCycleTime.toFixed(2) : null,
        interruptions: item?.meta?.interruptions,
      }
    })
  }

  addAnalyticsToFilterListView = (filteredLevelData, assetSummary) => {
    const results = []
    if (!isEmpty(filteredLevelData) && filteredLevelData[0].status?.toLowerCase() === 'completed') {
      filteredLevelData[0].assets.forEach((item) => {
        results.push(assetSummary.find(element => element.name === item.name))
      })
    }

    return results
  }
}

const sortAssetPropBySingleField = (data, field) => {
  return Object.entries<AssetSummaryBasicProp>(data)
    .map(([, item]) => ({
      key: item.name,
      ...item,
    }))
    .sort((a, b) => moment(b[field]).unix() - moment(a[field]).unix())
}

const isPourCompleted = itemStatus =>
  itemStatus ? itemStatus.toLowerCase() === 'completed' : false
