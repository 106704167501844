import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

interface Props {
  [x: string]: any
}

export class Footer extends React.Component<Props> {
  render() {
    const {
      settings: { isContentNoMaxWidth, year, presenterMode },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        {!presenterMode && (
          <div className={style.inner}>
            <div className="row">
              <div className="col-md-8">
                <p>
                  <strong>
                    YNOMIA &nbsp;&nbsp;|&nbsp;&nbsp; Accurate. &nbsp;Reliable. &nbsp;Automated.
                  </strong>
                </p>
                <p>
                  Ynomia offer a simple end-to-end technology solution that creates a connected
                  jobsite. Giving worksite personnel the ability to locate materials, tools, and
                  people across the site in real time - we put the supply chain in the hands of the
                  builders.
                </p>
                <p>&copy; {year} Ynomia - All Rights Reserved. Version: 
                  {process.env.REACT_APP_COMMIT_SHA?.substring(0, 7) || "Dev"}.
                </p>
              </div>
              <div className="col-md-4">
                <div className={style.logo}>
                  <img src="resources/images/ynomia-full-logo-dark.svg" alt="Ynomia Logo" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps)(Footer)
