import config from '../config'
import { MenuItem } from '@ynomia/core/dist/blueprint'

/**
 * Groups up categories into their appropriate groups
 * 
 * @param keys List of menu features
 * @param features Features data
 * @param includeDisabled If true, will ignore the enabled flag
 */
function buildCategoryNameObject(menu: MenuItem[]) {
  const categoryGroups = {}

  menu.forEach((menuItem) => {

    const categoryName = menuItem.category || 'other'
    if (categoryName && !categoryGroups[categoryName]) {
      categoryGroups[categoryName] = []
    }

    categoryGroups[categoryName].push({
      isSubMenuItem: false,
      title: menuItem.name,
      key: menuItem.name,
      icon: config.icons[menuItem.name] || menuItem.name,
      url: config.routes[menuItem.name] || `/${menuItem.name}`
    })

    // Push sub-menu items as well
    if (menuItem.menu) {
      menuItem.menu.forEach((submMenuItem) => {
        categoryGroups[categoryName].push({
          isSubMenuItem: true,
          title: submMenuItem.name,
          key: submMenuItem.name,
          icon: config.icons[submMenuItem.name] || submMenuItem.name,
          url: config.routes[submMenuItem.name] || `/${submMenuItem.name}`
        })
      })
    }
  })

  return categoryGroups
}

/**
 * Gets a list of menu items from features.
 * @param menu Menu from boostrap
 * @param includeDisabled Override enabled flag
 */
function processMenu(menu: MenuItem[]) {

  // arrange by category
  const categoryGroups = buildCategoryNameObject(menu)

  // generate output
  let generatedMenu = []
  Object.keys(categoryGroups).forEach((id) => {
    generatedMenu.push({ title: id, category: true })
    generatedMenu = generatedMenu.concat(categoryGroups[id])
  })

  return generatedMenu
}

export function extractMenuFromBootstrap(menu: MenuItem[]) {
  let finalMenu = [];

  if (menu) {
    finalMenu = processMenu(menu)
  }

  return finalMenu;
}
