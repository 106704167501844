import ynomiaclient from '../Client'

export default class Trackable {
  static useClient: any

  static async fetchAll(client?: any, args?: any): Promise<any> {
    Trackable.useClient = client ? client : ynomiaclient

    try {
      const bootstrap = Trackable.useClient.cache.current.bootstrap
      const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
      const tenantProjectString = `${tenant}::${scratchProjectCode}::`
      const assetTypes = bootstrap?.project?.asset_types?.types
        .map(asset => asset.id.split(tenantProjectString)[1])
        .filter(asset => asset)
      const observations = args?.observations || false

      // Disgusting hack to get Mace N6 working. Fix ASAP 22/10/21
      let trackable: any = { history: false }
      if (tenant === 'mace' && scratchProjectCode === 'n6') trackable = false

      const response = await Trackable.useClient.server.post(
        `/scratch/telemetry/search/${tenant}/${scratchProjectCode}`,
        {
          assetType: assetTypes,
          collections: { trackable, observations: observations },
          timeout: 10000,
        }
      )

      return response?.data?.value
    } catch (err) {
      return err
    }
  }
}
