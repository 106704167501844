import ynomiaclient from '../Client'
import moment from 'moment'
import ZonesService from 'services/Zones'
import { Zone } from 'config/interfaces'
import { find } from 'lodash'

export default class ZonesHistory {
  static useClient: any

  static async fetchAll(client?: any) {
    ZonesHistory.useClient = client ? client : ynomiaclient

    const bootstrap = ZonesHistory.useClient.cache.current.bootstrap
    const { client: tenant, scratchProjectCode } = bootstrap?.project?.metadata
    const zonesData = await ZonesService.fetchAll()

    const ids = Object.keys(zonesData.zones)
    const bookingResponses = []
    const returnResponses = []

    for (let id of ids) {
      const bookingResponse = await ZonesHistory.useClient.server.get(
        `/scratch/telemetry/observations/${tenant}/${scratchProjectCode}/${id}?action=zone_book&limit=5000`,
        {
          timeout: 10000,
        }
      )

      const returnResponse = await ZonesHistory.useClient.server.get(
        `/scratch/telemetry/observations/${tenant}/${scratchProjectCode}/${id}?action=zone_return&limit=5000`,
        {
          timeout: 10000,
        }
      )

      bookingResponses.push(...bookingResponse.data.value)
      returnResponses.push(...returnResponse.data.value)
    }

    const bookingsWithRefs = bookingResponses.filter(b => b.booking.reference)
    const returnsWithRefs = returnResponses.filter(r => r.reference)

    // combine our bookings with returns (if they have an associated return)
    const bookingsReturns = bookingsWithRefs.map((b) => {
      const { zoneId, zoneName, zoneShortName } = getZoneNames(b.monitorableID, zonesData.zones)
      const { returnedByUser, returnDate } = getReturnData(b.booking.reference, returnsWithRefs)
      return {
        id: b.booking.reference,
        zoneId,
        zoneName,
        zoneShortName,
        occupant: b.booking.contractor,
        timestamp: b.created,
        date: moment(b.created).format('DD/MM/YY'),
        from: moment(b.created).format('LT'),
        until: moment(b.booking.endTime).format('LT'),
        contractor: b.booking.fullName,
        phone: b.booking.mobile,
        authorizedBy: b.booking.approver,
        returnedByUser,
        returnDate,
      }
    })

    // return our enriched bookings and returns.
    return bookingsReturns
      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
      .map((b, i) => ({ ...b }))
  }
}

const getZoneNames = function (monitorableID, zones) {
  const found: Zone = find(zones, { id: monitorableID })
  if (found) {
    return {
      zoneId: found.externalID,
      zoneName: found.name,
      zoneShortName: found.order,
    }
  }
  return {}
}

const getReturnData = function (reference, arrayOfReturns) {
  const returnObject = arrayOfReturns.find(r => r.reference === reference)
  if (returnObject) {
    return {
      returnDate: moment(returnObject.created).format('DD/MM/YY H:mm A'),
      returnedByUser: returnObject.returnedBy || 'Unavailable',
    }
  }
  return {}
}
