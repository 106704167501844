import { Observable, Subscription } from 'rxjs'
import { InMemoryCacheData } from '@ynomia/client'
import { notification } from 'antd'
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ID_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  PROJECT_ID_STORAGE_KEY,
} from '../../config/constants'
import { history } from '../../redux/store'

interface AuthObservables {
  onEstablish$: Observable<InMemoryCacheData>
  onRefresh$: Observable<InMemoryCacheData>
  onTerminate$: Observable<InMemoryCacheData>
}

/**
 * This module listens for changes in the authentication status of the current user.
 */
class AuthObserver {
  private onEstablishSubscription?: Subscription
  private onRefreshSubscription?: Subscription
  private onTerminateSubscription?: Subscription

  /**
   * Use this method after establishing a new `SessionManager` instance for `@ynomia/client` to
   * subscribe this observer class to the Auth Observables provided.
   * @param {AuthObservables} authObservables
   * @return {void}
   */
  initialize({ onEstablish$, onRefresh$, onTerminate$ }: AuthObservables): void {
    // 1. Unsubscribe from any existing auth observations to avoid memory leaks.
    if (this.onEstablishSubscription) {
      this.onEstablishSubscription.unsubscribe()
    }
    if (this.onRefreshSubscription) {
      this.onRefreshSubscription.unsubscribe()
    }
    if (this.onTerminateSubscription) {
      this.onTerminateSubscription.unsubscribe()
    }
    // 2. Subscribe to the new observables and bind them to their own event handlers.
    this.onEstablishSubscription = onEstablish$.subscribe(AuthObserver.onEstablish)
    this.onRefreshSubscription = onRefresh$.subscribe(AuthObserver.onRefresh)
    this.onTerminateSubscription = onTerminate$.subscribe(AuthObserver.onTerminate)
  }

  /**
   * @event onEstablish
   * Emits when a brand new user session is established. This happens typically after a user has
   * just signed in. Note that this event is NOT emitted when restoring existing sessions that
   * have been initialized from local storage data.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onEstablish(clientCache: InMemoryCacheData): void {
    AuthObserver.persistAuthDataOffline(clientCache)
  }

  /**
   * @event onRefresh
   * Emits each time a new access token is attained using the user's refresh token.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onRefresh(clientCache: InMemoryCacheData): void {
    AuthObserver.persistAuthDataOffline(clientCache)
  }

  /**
   * @event onTerminate
   * Emits when a previously authenticated user session has been terminated. This can occur for
   * a number of reasons, including token expiry (with no successful refresh) and the session
   * being manually destroyed (e.g. when signing out).
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onTerminate(clientCache: InMemoryCacheData): void {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
    localStorage.removeItem(ID_TOKEN_STORAGE_KEY)
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
    localStorage.removeItem(PROJECT_ID_STORAGE_KEY)
    if (clientCache.auth.unauthenticatedReason === 'expired') {
      notification.warning({
        message: 'Session Expired',
        description: 'Enter your credentials to log into Ynomia.',
      })
    }
    if (clientCache.auth.unauthenticatedReason === 'user_initiated') {
      notification.info({
        message: 'Logged Out',
        description: 'You have been successfully logged out.',
      })
      // Ensures that the default `?redirect_to=` logic is not setup when manually signing out.
      history.push('/system/login')
    }
  }

  /**
   * Saves mission critical authentication data offline so that sessions can be restored after
   * refreshing the browser.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static persistAuthDataOffline(clientCache: InMemoryCacheData): void {
    AuthObserver.saveOffline(ACCESS_TOKEN_STORAGE_KEY, clientCache.auth.accessToken)
    AuthObserver.saveOffline(ID_TOKEN_STORAGE_KEY, clientCache.auth.idToken)
    AuthObserver.saveOffline(REFRESH_TOKEN_STORAGE_KEY, clientCache.auth.refreshToken)
    AuthObserver.saveOffline(PROJECT_ID_STORAGE_KEY, clientCache.projectId)
  }

  /**
   * Saves data offline into local storage, but only if the value exists.
   * @param {string} key
   * @param {string?} value
   * @return {void}
   */
  private static saveOffline(key: string, value?: string): void {
    if (value) {
      localStorage.setItem(key, value)
    }
  }
}

const authObserver = new AuthObserver()

export default authObserver
