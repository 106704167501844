import actions from './actions'
import { takeEvery, call, put } from 'redux-saga/effects'
import client from '../../services/Client'
import LayerService from 'services/Layers'
import { BeaconStatus, BeaconIconStatus } from 'pages/dashboard/networkManagement/interface'
import { LayerTreeHelper, enrichLayers } from 'utils'
import { keyBy } from 'lodash'

/** This function is currently used by Network management page. Unfortunately it's only limited to looking for level. Will need to create a fetchAllLayersv2 to cater for different types of layers */
export function* fetchAllLayers() {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      // This saga is designed to always run in the background (due to the critical nature of
      // the data it fetches), but we don't want to execute it if the user becomes unauthenticated.
      return
    }

    const layers = yield call(LayerService.fetchAll, client)
    const layersData = layers.value
      .map((layer) => {
        // TODO : Keeping includes("level") check for backward compatiblity. It should be removed
        if (layer.type.includes('level') || layer.isPrimary) { 
          return {
            ...layer,
            beacons: layer.beacons
              ? Object.values(layer.beacons).map((beacon: any) => {
                  return {
                    ...beacon,
                    status: beacon.geoJSON ? BeaconStatus.pinned : null,
                    icon: BeaconIconStatus.default,
                  }
                })
              : [],
          }
        }
        return false
      })
      .filter(layer => layer)
    yield put({
      type: actions.FETCH_ALL_LAYERS_SUCCESS,
      payload: layersData,
    })
  } catch {
    yield put({ type: actions.FETCH_ALL_LAYERS_FAILURE })
  }
}

/** This is the ideal way of fetching all layers from API. It matches the data format that
 *  we have in bootstrap.
 *
 * It does 3 things
 * 1. fetch all layers from API
 * 2. decorate layer with layerHelper
 * 3. update bootstrap project layers.
 */
export function* fetchAllLayersv2() {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      // This saga is designed to always run in the background (due to the critical nature of
      // the data it fetches), but we don't want to execute it if the user becomes unauthenticated.
      return
    }

    const { value: layers } = yield call(LayerService.fetchAll, client)

    const layerHelper = new LayerTreeHelper(layers)
    const primaryLayers = layerHelper.getPrimaryLayers()
    client.cache.current.bootstrap.project.layers = layers
    client.cache.current.bootstrap.project.decoratedLayers = {
      rootLayer: layerHelper.getRootLayer(),
      primaryLayers: primaryLayers,
      enrichedLayers: enrichLayers(client.cache.current.bootstrap?.project?.layers, layerHelper),
      isMultiLevel: Object.keys(primaryLayers).length > 1,
    }
    const updatedLayersIds = keyBy(layers, 'id')
    client.cache.current.bootstrap.project.idsIndex = {
      ...client.cache.current.bootstrap.project.idsIndex,
      ...updatedLayersIds,
    }

    yield put({
      type: actions.FETCH_ALL_LAYERS_SUCCESS,
    })
  } catch {
    yield put({ type: actions.FETCH_ALL_LAYERS_FAILURE })
  }
}

export function* addLayers(request: { [x: string]: any }) {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      // This saga is designed to always run in the background (due to the critical nature of
      // the data it fetches), but we don't want to execute it if the user becomes unauthenticated.
      return
    }

    const response = yield call(LayerService.addLayers, request.payload, client)
    if (response) {
      yield put({
        type: actions.ADD_LAYERS_SUCCESS,
      })
      yield put({
        type: actions.FETCH_ALL_LAYERS_V2,
      })
    }
  } catch {
    yield put({ type: actions.FETCH_ALL_LAYERS_FAILURE })
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.FETCH_ALL_LAYERS, fetchAllLayers)
  yield takeEvery(actions.FETCH_ALL_LAYERS_V2, fetchAllLayersv2)
  yield takeEvery(actions.ADD_LAYERS, addLayers)
}
