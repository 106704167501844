import YnomiaClient from './YnomiaClient'

/**
 * Note that the container is exported as an initialized class instance. This is
 * so that we can share around the client state with all modules of the mobile
 * application.
 *
 * Usage Example:
 * import client from './services/Client'
 * client.server.get('/route')
 */
const client = new YnomiaClient()

export default client
