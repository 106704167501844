import { filterAllAssetsBy } from 'pages/dashboard/facade/status/helper/helpers'
import actions from './actions'
import { DashboardStructureHelper } from 'pages/dashboard/facade/status/helper/dashboardStructureHelper'

const initialState = {
  RUN_RATE: {
    meta: {},
    values: {},
    original: {},
  },
  ASSET_SUMMARY: {
    meta: {},
    values: {},
    original: {},
    filteredList: {},
  },
  HATCH_SUMMARY: {
    meta: {},
    values: {},
    original: {},
    listView: {},
  },
  DEVICES_SUMMARY: {
    meta: {},
    values: {},
    original: {},
    listView: {},
  },
  API_CACHE_DATA: {
    hvmtData: {}, 
    telemData: [], 
    blueprintsStructureData: {}
  },
  CUMULATIVE_STATUS: {
    meta: {},
    values: []
  }
}

const aggregateAssetSummaryPayload = (state, action) => {
  if (!action.payload.ASSET_SUMMARY?.selectedLevel && !action.payload.ASSET_SUMMARY?.selectedAssets) {
    return {
      ...action.payload.ASSET_SUMMARY,
      filteredList: action.payload.original,
      selectedLevel: action.payload.ASSET_SUMMARY?.selectedLevel,
      selectedAssets: action.payload.ASSET_SUMMARY?.selectedAssets,
    }
  } else {
    const payload = {
      ...action.payload.ASSET_SUMMARY,
      filteredList: filterAllAssetsBy(
        state.ASSET_SUMMARY.original,
        action.payload.ASSET_SUMMARY.selectedLevel,
        action.payload.ASSET_SUMMARY.selectedAssets
      ),
      selectedLevel: action.payload.ASSET_SUMMARY?.selectedLevel,
      selectedAssets: action.payload.ASSET_SUMMARY?.selectedAssets,
    }
    return payload
  }
}

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_REPORT:
      const stateToApply = { ...state, ...action.payload }
      // Process and apply ASSET_SUMMARY, only when present in the payload
      if (action.payload.ASSET_SUMMARY) {
        stateToApply.ASSET_SUMMARY = aggregateAssetSummaryPayload(state, action)
      }
      return stateToApply
    case actions.UPDATE_REPORT_POURS:
      const {hvmtData, telemData, blueprintsStructureData} = state.API_CACHE_DATA
      const {externalID, interruptions, plannedPourDate} = action.payload

      for (let i = 0; i < telemData.length; i++) {
        if (telemData[i].externalID === externalID) {
          telemData[i].meta.interruptions = interruptions
          telemData[i].meta.plannedDate = plannedPourDate
          break
        }
      }

      const combinedData = new DashboardStructureHelper().compileDataFromApis( hvmtData, telemData, blueprintsStructureData)

      const newState = {
        ...state,
        ASSET_SUMMARY: {
          ...state.ASSET_SUMMARY,
          mixedPour: combinedData
        }
      }

      return newState
    default:
      return state
  }
}
