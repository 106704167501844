import moment from 'moment'

/**
 * Calculates the time remaining to a date in the future.
 * @param {Date} from: Timestamp to calculate from.
 * @param {number} duration: How far away from the timestamp do we want to countdown to? This
 * value is in milliseconds.
 * @return {number} Milliseconds remaining. Returns 0 if the target time has passed.
 */
export const timeLeft = (from: Date, duration: number): number => {
  const now: number = Date.now()
  let targetTime: number = from.valueOf()
  targetTime += duration
  const millisecondsRemaining = targetTime - now
  return millisecondsRemaining > 0 ? millisecondsRemaining : 0
}

/**
 * Formats milliseconds into hours, minutes, seconds.
 * @param {number} milliseconds: Must not be larger than 24 hours.
 * @return {object} Eg: {hoursStr: `20`, minutesStr `03`, secondsStr `30`}
 */
export const formatCountdownMilliseconds = (milliseconds: number): { [unit: string]: string } => {
  const seconds = Math.floor((milliseconds / 1000) % 60)
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60)
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24)
  const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`
  const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`
  const hoursStr = hours < 10 ? `0${hours}` : `${hours}`
  return { hoursStr, minutesStr, secondsStr }
}

/**
 * Formats milliseconds into a human readable countdown format.
 * @param {number} milliseconds: Must not be larger than 24 hours.
 * @return {string} Eg: `00:01` for 1000.
 */
export const formatCountdownMillisecondsHHMMSS = (milliseconds: number): string => {
  const { hoursStr, minutesStr, secondsStr } = formatCountdownMilliseconds(milliseconds)
  // Do not return hours if there are no hours to show
  if (hoursStr === '00') {
    return `${minutesStr}:${secondsStr}`
  }
  return `${hoursStr}:${minutesStr}:${secondsStr}`
}

/**
 * Formats milliseconds into a human readable countdown format.
 * @param {number} milliseconds: Must not be larger than 24 hours.
 * @return {string} Eg: `00:01` for 1000.
 */
export const formatCountdownMillisecondsHHMM = (milliseconds: number): string => {
  const { hoursStr, minutesStr } = formatCountdownMilliseconds(milliseconds)
  return `${hoursStr}:${minutesStr}`
}

/**
 * Formats a timestamp into a human readable time, eg: '2:15pm'.
 * @param {Date|string|number} timestamp
 * @return {string}
 */
export const formatTime = (timestamp: Date | string | number): string => {
  return moment(timestamp).format('h:mma')
}

export const formatDate = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD')
}

export const sameDay = (d1, d2) => {
  return moment(d1).isSame(d2, 'day')
}

export const convertMinutesToMilliSeconds = (minutes) => {
  return 1000 * 60 * minutes
}

const getCalendarWorkDay = (workdays) => {
  let workDayArray = []
  Object.keys(workdays).forEach((day) => {
    if (workdays[day]) workDayArray.push(WeekDay[day])
  })
  return workDayArray
}

export const setupMomentLocale = (projectCalendar, localeSetting): void => {
  moment.updateLocale(localeSetting, {
    holidays: projectCalendar.holidays,
    holidayFormat: projectCalendar.dateFormat,
    workingWeekdays: getCalendarWorkDay(projectCalendar.workdays),
  })
}

export enum WeekDay {
  'monday' = 1,
  'tuesday' = 2,
  'wednesday' = 3,
  'thursday' = 4,
  'friday' = 5,
  'saturday' = 6,
  'sunday' = 0,
}
