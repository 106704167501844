import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import report from './report/reducers'
import zones from './zones/reducers'
import pours from './pours/reducers'
import screens from './screens/reducers'
import heartbeat from './heartbeat/reducers'
import photos from './photos/reducers'
import update from './update/reducers'
import zonesHistory from './zonesHistory/reducers'
import networkManagement from './networkManagement/reducers'
import locationPlayground from './locationPlayground/reducers'
import trackable from './trackable/reducers'
import assets from './assets/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    report,
    zones,
    pours,
    heartbeat,
    photos,
    update,
    zonesHistory,
    screens,
    networkManagement,
    locationPlayground,
    trackable,
    assets,
  })
