import { isEmpty } from 'lodash'

export const transformToStatusByLevelChart = (input, blacklist = []) => {
  // create buckets
  let buckets = Object.keys(input).reduce((obj, n) => {
    Object.keys(input[n]).forEach((status) => {
      if (blacklist.includes(status)) {
        return obj
      }
      if (!obj.hasOwnProperty(status)) {
        obj[status] = []
      }
    })
    return obj
  }, {})
  // bucket results, default to 0
  Object.keys(input).forEach((n) => {
    Object.keys(buckets).forEach((status) => {
      if (input[n].hasOwnProperty(status)) {
        buckets[status].push(input[n][status])
      } else {
        buckets[status].push(0)
      }
    })
  })
  // format and return
  return Object.keys(buckets).map(k => ({ name: k, data: buckets[k] }))
}

export const transformToStatusTotalChart = (input, projectStati, blacklist = []) => {
  // create buckets
  let buckets = Object.keys(input).reduce((obj, n) => {
    Object.keys(input[n]).forEach((status) => {
      if (blacklist.includes(status)) {
        return obj
      }
      if (!obj.hasOwnProperty(status)) {
        obj[status] = []
      }
    })
    return obj
  }, {})
  // bucket results, default to 0
  Object.keys(input).forEach((n) => {
    Object.keys(buckets).forEach((status) => {
      if (input[n].hasOwnProperty(status)) {
        buckets[status].push(input[n][status])
      } else {
        buckets[status].push(0)
      }
    })
  })

  if (!projectStati) {
    return Object.keys(buckets).map(k => ({
      name: k,
      count: buckets[k].reduce((a, b) => a + b, 0),
    }))
  }

  let out = [...projectStati]

  // format and return
  Object.keys(buckets).forEach((k) => {
    out[out.indexOf(k)] = { name: k, count: buckets[k].reduce((a, b) => a + b, 0) }
  })

  // apply defaults where network has not returned data for particilar status in the project set of stati.
  out.forEach((n, i) => {
    if (typeof n === 'string') {
      out[i] = { name: n, count: 0 }
    }
  })

  return out
}

export const supplyChainOrderSort = function (data, config, labels) {
  if (data.length === 0) {
    return data
  }

  let out = [...config]

  // If the set of labels includes "Not Yet Tracked", force this to the end position
  if (window.location.href.indexOf('untrackedAtStart') === -1) {
    // uae = untracked-at-end
    const indexOfNotYetTracked = out.indexOf('Not Yet Tracked')
    if (indexOfNotYetTracked !== -1) {
      out.splice(indexOfNotYetTracked, 1) // remove this specific item out of the array
      out.push('Not Yet Tracked') // add it to the end of the array
    }
  }

  // Set defaults
  out.forEach((status, i) => {
    out[i] = {
      name: status,
      data: generateRange(labels.length, 0),
      count: 0,
    }
    // Override with actuals where data exists
    data.forEach((dataStatus, j) => {
      if (dataStatus.name === status) {
        out[i].data = dataStatus.data
      }
    })
    out[i].count = out[i].data.reduce((a, b) => a + b, 0)
  })

  return out
}

export const generateRange = function (length, value) {
  let out = []
  for (var i = 0; i < length; i++) {
    out.push(value)
  }
  return out
}

/* -- LEVEL SUMMARY TRANSFORMER -- */
// STEP A. Build up a useful index.
export const levelSummaryTransformer = function (rawReportData, options: any = {}) {
  if (options.splitBy === 'type') {
    rawReportData = formatDataForSeriesByType(rawReportData)
  }

  const result = rawReportData.reduce(
    (out, obj) => {
      obj.assets.forEach((assetObj) => {
        if (!out.labelsIndex.hasOwnProperty(assetObj.level)) {
          out.labelsIndex[assetObj.level] = {
            name: assetObj.level,
            count: 0,
            assets: [],
            statusIndex: {},
            typeIndex: {},
          }
          out.stati.forEach((s) => {
            out.labelsIndex[assetObj.level].statusIndex[s] = {
              name: s,
              count: 0,
              assets: [],
            }
          })
          out.types.forEach((t) => {
            out.labelsIndex[assetObj.level].typeIndex[t] = {
              name: t,
              count: 0,
              assets: [],
            }
          })
          out.labels.push(assetObj.level)
        }

        out.labelsIndex[assetObj.level].assets.push(assetObj.name)
        out.labelsIndex[assetObj.level].count = out.labelsIndex[assetObj.level].assets.length

        out.labelsIndex[assetObj.level].statusIndex[obj.status].assets.push(assetObj.name)
        out.labelsIndex[assetObj.level].statusIndex[obj.status].count =
          out.labelsIndex[assetObj.level].statusIndex[obj.status].assets.length

        out.labelsIndex[assetObj.level].typeIndex[obj.type].assets.push(assetObj.name)
        out.labelsIndex[assetObj.level].typeIndex[obj.type].count =
          out.labelsIndex[assetObj.level].typeIndex[obj.type].assets.length
      })
      return out
    },
    {
      labels: [],
      seriesByType: seriesTemplate('type'),
      series: seriesTemplate(),
      labelsIndex: {},
      stati: rawReportData.map(r => r.status),
      types: rawReportData.map(r => r.type),
    }
  )

  // STEP B. Sort our Levels
  result.labels = customLevelSorter(result.labels)

  // STEP C1. Push default 0 values per level
  const seriesDataTemplate = []
  result.labels.forEach(l => seriesDataTemplate.push(0))
  result.series = result.series.map((seriesObj) => {
    // set defaults at 0
    return {
      ...seriesObj,
      data: [...seriesDataTemplate],
    }
  })

  // STEP C2. Push default 0 values per level
  const seriesByTypeDataTemplate = []
  result.labels.forEach(l => seriesByTypeDataTemplate.push(0))
  result.seriesByType = result.seriesByType.map((seriesObj) => {
    // set defaults at 0
    return {
      ...seriesObj,
      data: [...seriesByTypeDataTemplate],
    }
  })

  // STEP D1. Override the defaults with actuals.
  result.series.forEach((seriesObj) => {
    seriesObj.data.forEach((v, i) => {
      let floor = result.labels[i]
      seriesObj.data[i] = result.labelsIndex[floor].statusIndex[seriesObj.name].count
    })
  })

  // STEP D2. Override the defaults with actuals.
  result.seriesByType.forEach((seriesObj) => {
    seriesObj.count = 0
    seriesObj.data.forEach((v, i) => {
      let floor = result.labels[i]
      seriesObj.data[i] = result.labelsIndex[floor].typeIndex[seriesObj.name].count
      seriesObj.count += seriesObj.data[i]
    })
  })

  // STEP E. Return transformed result
  return result

  /* -- helpers -- */

  function seriesTemplate(mode = 'status') {
    return rawReportData.map((r) => {
      return { name: r[mode], data: [] }
    })
  }

  // console.log("Sorted?", customLevelSorter(['L03', 'L01', 'L28', 'LG-10', 'L15']))
  // expect LG10, L01, L03, L15, L28 (yey)
  /* -------------------- */
}

export function customLevelSorter(inputArrayOfLevels) {
  let sortedResult = []
  // create buckets that levels should be sorted within
  // eg, LG-03, LG-01, LG-02 would get sorted within its respective classification
  const numbericLevels = []
  const LGLevels = []
  const UnassignedLevels = []
  const CWPWWLevels = []
  const RoofLevels = []

  // Split into LG vs. !LG groups
  inputArrayOfLevels.forEach((k) => {
    if (k.includes('U.A')) {
      UnassignedLevels.push(k)
    } else if (k.includes('LG')) {
      LGLevels.push(k)
    } else if (k.includes('CWP') || k.includes('WW')) {
      CWPWWLevels.push(k)
    } else if (k.includes('ROOF')) {
      RoofLevels.push(k)
    } else {
      const isNumeric = extractFirstNumberGroup(k)
      if (isNumeric) {
        numbericLevels.push(k)
      } else {
        UnassignedLevels.push(k)
      }
    }
  })

  // Sort the standard levels.
  numbericLevels.sort(numericThenAlphaSort)

  // Sort the bespoke levels.
  CWPWWLevels.sort(numericThenAlphaSort)

  // Combine LG and Standard
  sortedResult = [
    ...LGLevels,
    ...numbericLevels,
    ...CWPWWLevels,
    ...RoofLevels,
    ...UnassignedLevels,
  ]

  return sortedResult
}

function extractFirstNumberGroup(str) {
  try {
    return Number(str.match(/\d+/)[0])
  } catch (e) {
    // TODO: add proper error to log in sentry
    console.log(`${str} Not a numeric`)
    return null
  }
}

function numericThenAlphaSort(a, b) {
  // obtain the first numeric component of the string.
  const numericA = extractFirstNumberGroup(a)
  const numericB = extractFirstNumberGroup(b)

  // if the numeric components are identical,
  // the strings are compared in full, allowing
  // Level5b to be greater than Level5a
  if (numericA === numericB) {
    return a > b ? 1 : -1
  }

  // if the numeric components are non identical,
  // the smallest number is ordered first.
  return numericA > numericB ? 1 : -1
}

function formatDataForSeriesByType(data) {
  const types = new Set()
  data.forEach((o) => {
    o.assets.forEach((a) => {
      types.add(a.type)
    })
  })

  const out = []
  types.forEach((t) => {
    const obj = {
      type: t,
      assets: [],
      count: 0,
    }

    data.forEach((o, i) => {
      const assetsOfThisStatus = [...o.assets.filter(a => a.type === t)]
      const assetsOfThisStatusTweaked = assetsOfThisStatus.map(a => ({
        ...a,
        status: o.status,
      }))
      obj.assets = [...obj.assets, ...assetsOfThisStatusTweaked]
      obj.count = obj.assets.length

      // filter out undesired status if needed.
      obj.assets = obj.assets.filter(a => a.status !== 'Pending')
    })

    out.push(obj)
  })
  return out
}

export const filterAllAssetsByLevel = (assetsData = [], selectedLevel) => {
  if (!selectedLevel || selectedLevel === '') return assetsData

  return assetsData
    .reduce((a, b) => {
      a.push({ ...b, assets: b.assets.filter(e => e.level === selectedLevel) })
      return a
    }, [])
    .filter(e => e.assets.length > 0)
}

export const filterAllAssetsBy = (assetsData = [], selectedLevel, selectedAssets) => {
  if (!selectedLevel && !selectedAssets) return assetsData
  return assetsData
    .reduce((a, b) => {
      a.push({
        ...b,
        assets: b.assets.filter((e) => {
          let keep = true
          if (selectedLevel) keep = e.level === selectedLevel
          if (selectedAssets) keep = selectedAssets.includes(e.name)
          return keep
        }),
      })
      return a
    }, [])
    .filter(e => e.assets.length > 0)
}

const calcPercentage = (totalAssetsCount, count) => {
  if (totalAssetsCount > 0) {
    return ((count / totalAssetsCount) * 100).toFixed(1)
  } else {
    return 0
  }
}

export const populateListViewData = (filteredData, originalData) => {
  let listViewData = []
  let totalAssetsCount: number = 0
  if (originalData && !isEmpty(originalData)) {
    let useData = filteredData && !isEmpty(filteredData) ? filteredData : originalData

    totalAssetsCount = useData.reduce((count, line) => {
      return line.assets.length + count
    }, 0)

    useData.forEach((obj) => {
      listViewData.push({
        key: obj.status,
        status: obj.status,
        assets: obj.assets.map(asset => asset.name),
        quantity: obj.assets.length,
        percentage: `${calcPercentage(totalAssetsCount, obj.assets.length)}%`,
      })
    })
  }
  return { listViewData, totalAssetsCount }
}
