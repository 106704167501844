import { checkFeature } from 'services/FeatureAccess'

export const showLockdown = () => {
  return checkFeature('safety screens.lockdown_features')
}

export const showScreenVisualization = () => {
  return checkFeature('safety screens.screen_visualization')
}

export const canPerformScreenActions = () => {
  return checkFeature('safety screens.general_actions')
}
