import { put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import ZonesHistoryService from '../../services/ZonesHistory'
import client from '../../services/Client'


export function* fetchAllZonesHistory() {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      // TODO: Consider if this is necessary
      return
    }
    const zones = yield call(ZonesHistoryService.fetchAll)
    const { uniqueOccupants, uniqueZones } = processZonesMetadata(zones)
    yield put({
      type: actions.FETCH_ALL_ZONES_HISTORY_SUCCESS,
      payload: { zones, uniqueOccupants, uniqueZones },
    })
  } catch {
    // yield put({ type: actions.FETCH_ALL_ZONES_FAILURE })
  }
}

const processZonesMetadata = (zones) => {
  const uniqueZones = [...new Set(zones.map(item => item.zoneShortName))]
  const orderedUniqueZones = uniqueZones.sort((a:string, b:string) => (parseInt(a) > parseInt(b)) ? 1 : -1)
  return {
    uniqueOccupants: [...new Set(zones.map(item => item.occupant))].sort(),
    uniqueZones: orderedUniqueZones
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.FETCH_ALL_ZONES_HISTORY, fetchAllZonesHistory)
}
