import actions from './actions'

const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  loading: false,
  isSwitchingProjects: false,
  passwordResetLoading: false,
  passwordResetSent: false,
  project: {},
  projects: [],
  selectedProjectId: null,
  liveData: false,
  client: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.LOGIN:
      return { ...state, loading: true }
    case actions.SEND_PASSWORD_RESET_EMAIL:
      return { ...state, passwordResetLoading: true }
    case actions.LOAD_CURRENT_ACCOUNT:
      return { ...state, loading: true }
    case actions.SWITCH_PROJECT:
      return { ...state, isSwitchingProjects: true }
    default:
      return state
  }
}

export const getUser = state => state.user
