import { all } from 'redux-saga/effects'
import user from './user/sagas'
import settings from './settings/sagas'
import report from './report/sagas'
import zones from './zones/sagas'
import pours from './pours/sagas'
import heartbeat from './heartbeat/sagas'
import photos from './photos/sagas'
import update from './update/sagas'
import zonesHistory from './zonesHistory/sagas'
import networkManagement from './networkManagement/sagas'
import trackables from './trackable/sagas'
import assets from './assets/saga'

export default function* rootSaga() {
  yield all([
    user(),
    settings(),
    report(),
    zones(),
    pours(),
    heartbeat(),
    photos(),
    update(),
    zonesHistory(),
    networkManagement(),
    trackables(),
    assets(),
  ])
}
