import React from 'react'
import { connect } from 'react-redux'

interface Props {
  [x: string]: any
}

interface State {
  [x: string]: any
}

@connect(({ user }) => ({ user }))
class Status extends React.Component<Props, State> {
  render() {
    // const text = window.location.hostname === 'ynomia.com' ? 'Live' : 'Demo'
    const text = 'Connected'
    const colorClass = text === 'Connected' ? 'bg-success' : 'bg-warning'
    return (
      <div>
        <span className={`${colorClass} ml-2 p-1 badge text-white font-size-12 text-uppercase`}>
          {text}
        </span>
      </div>
    )
  }
}

export default Status
