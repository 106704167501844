import actions from './actions'

const initialState = {
  data: [],
  recentHistory: {
    asMinuteIndex: {},
    isHistoryFetching: false,
    lastHistoryFetchFailed: false,
    firstHistoryFetchFailureTime: undefined,
    lastHistoryFetchStartTime: undefined,
    lastHistoryFetchSuccessTime: undefined,
  },
  isFetching: false,
  lastFetchFailed: false,
  firstFetchFailureTime: undefined,
  lastFetchStartTime: undefined,
  lastFetchSuccessTime: undefined,
  reportData: {},
  isReportFetching: false,
  lastReportFetchFailed: false,
  firstReportFetchFailureTime: undefined,
  lastReportFetchStartTime: undefined,
  lastReportFetchSuccessTime: undefined,
}

export default function trackableReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_TRACKABLE:
      return {
        ...state,
        isFetching: true,
        lastFetchStartTime: new Date(),
      }

    case actions.FETCH_ALL_TRACKABLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastFetchFailed: false,
        lastFetchSuccessTime: new Date(),
        firstFetchFailureTime: undefined,
        data: action.payload,
      }
    case actions.FETCH_ALL_TRACKABLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastFetchFailed: true,
        firstFetchFailureTime: state.firstFetchFailureTime || new Date(),
      }
    // TODO: temporary fix to differentiate path from activity/map. Remove this once it's finalized
    case actions.FETCH_ALL_TRACKABLE_REPORT:
      return {
        ...state,
        isReportFetching: true,
        lastReportFetchStartTime: new Date(),
      }
    case actions.FETCH_ALL_TRACKABLE_REPORT_SUCCESS:
      return {
        ...state,
        isReportFetching: false,
        lastReportFetchFailed: false,
        lastReportFetchSuccessTime: new Date(),
        firstReportFetchFailureTime: undefined,
        reportData: action.payload,
      }
    case actions.FETCH_ALL_TRACKABLE_REPORT_FAILURE:
      return {
        ...state,
        isReportFetching: false,
        lastReportFetchFailed: true,
        firstReportFetchFailureTime: state.firstReportFetchFailureTime || new Date(),
      }

    case actions.CLEAR_ALL_TRACKABLE_REPORT:
      return initialState
    /* Handling Trackable Recent History */
    case actions.FETCH_RECENT_HISTORY:
      return {
        ...state,
        recentHistory: {
          ...state.recentHistory,
          isHistoryFetching: true,
          lastHistoryFetchStartTime: new Date(),
        }
      }
    case actions.FETCH_RECENT_HISTORY_SUCCESS:
      return {
        ...state,
        recentHistory: {
          ...state.recentHistory,
          ...action.payload,
          isHistoryFetching: false,
          lastHistoryFetchFailed: false,
          lastHistoryFetchSuccessTime: new Date(),
          firstHistoryFetchFailureTime: undefined,
        }
      }
      case actions.FETCH_RECENT_HISTORY_FAILURE:
        return {
          ...state,
          recentHistory: {
            ...state.recentHistory,
            isHistoryFetching: false,
            lastHistoryFetchFailed: true,
            firstHistoryFetchFailureTime: state.recentHistory.firstHistoryFetchFailureTime || new Date(),
          }
        }
    default:
      return state
  }
}
