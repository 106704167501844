import { GeoJSON, Layerv2 } from 'config/interfaces'

export enum BeaconIconStatus {
  default = '2',
  muted = '1',
}

export type drawModeProp = {
  id: string | null
  originalGeoJSON?: GeoJSON
}

export interface BeaconProp {
  id: string
  name?: string // TODO: remove this and use label instead
  status?: string
  geoJSON?: GeoJSON
  icon?: BeaconIconStatus // value for twin to render icon
  created?: Date
  updated?: Date
  isArchived?: boolean
  label?: string
  key?: string
}

export interface RawTwinBleaconData extends Partial<BeaconProp> {
  tempId?: string
  raw?: {
    geoJSON: GeoJSON
    id: string
  }
}

export enum BeaconStatus {
  init = 'initialized', // initialized, no post atm
  // created = 'created', // not in use for now
  saving = 'saving', // state to indicate submission to twin
  pinned = 'pinned', // twin received & confirm position
}

export enum LayerType {
  section = 'section',
  zone = 'zone',
  level = 'level',
  site = 'site',
}

export interface NetworkManagementLayer extends Partial<Layerv2> {}
