import { all, call, takeEvery, takeLeading, put } from 'redux-saga/effects'
import { notification } from 'antd'
import NProgress from 'nprogress'
import { history } from '../store'
import { login, logout, bootstrap, sendResetPasswordEmail } from '../../services/users'
import { extractMenuFromBootstrap } from '../../services/menu'
import userActions from './actions'
import menuActions from '../menu/actions'
import client from '../../services/Client'
import dispatchScheduler from '../../services/DispatchScheduler'
import heartbeatActions from '../heartbeat/actions'
import updateActions from '../update/actions'
import {
  HEARTBEAT_INTERVAL,
  WEBHQ_AUTO_REFRESH_CHECK_INTERVAL,
  PROJECT_ID_STORAGE_KEY,
} from '../../config/constants'
import { setupMomentLocale } from 'utils/time'
import moment from 'moment'

const { SET_STATE } = userActions

export function* LOAD_USER_SETTINGS() {
  const { menu } = client.cache?.current?.bootstrap?.project

  if (!menu) {
    return
  }

  const mData = extractMenuFromBootstrap(menu)
  yield put({
    type: menuActions.SET_STATE,
    payload: { menuData: mData },
  })
}

export function SET_MOMENT_LOCALE() {
  const { calendar } = client.cache?.current?.bootstrap?.project
  setupMomentLocale(calendar, moment.locale())
}

export function* LOGIN({ type, payload: { email, password, staySignedIn } }) {
  const success = yield login(email, password, staySignedIn)

  if (success) {
    yield history.push('/' + history.location.search)
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in to Ynomia Construction HQ',
    })

    // Load user settings
    yield call(LOAD_USER_SETTINGS)

    // Indicate that the entire login process has finished
    yield put({
      type: SET_STATE,
      payload: {
        loading: false,
      },
    })
  } else {
    notification.warning({
      message: 'Failed to login',
      description: 'Please check your username and password',
    })
    yield put({
      type: SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  yield logout()
}

export function* SEND_PASSWORD_RESET_EMAIL({ type, payload: { email } }) {
  try {
    yield sendResetPasswordEmail(email)
    yield put({
      type: SET_STATE,
      payload: {
        passwordResetLoading: false,
        passwordResetSent: true,
      },
    })
  } catch (error) {
    notification.warning({
      message: 'Password Reset Failed',
      description: 'An unknown error occurred. Please try again in a few moments.',
    })
    yield put({
      type: SET_STATE,
      payload: {
        passwordResetLoading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT({ type, payload: { projectId } }) {
  try {
    yield bootstrap(projectId)
    // Load user settings
    yield call(LOAD_USER_SETTINGS)

    // setup moment locale for project calendar. Will help with calculating business days
    yield call(SET_MOMENT_LOCALE)

    // Indicate that the entire login process has finished
    yield put({
      type: SET_STATE,
      payload: {
        loading: false,
      },
    })
    // Start the connectivity heartbeat (if not already initialised).
    dispatchScheduler.start(heartbeatActions.CREATE_HEARTBEAT, HEARTBEAT_INTERVAL)
    // Start the update checker.
    dispatchScheduler.start(updateActions.CHECK_FOR_UPDATE, WEBHQ_AUTO_REFRESH_CHECK_INTERVAL)
  } catch {
    yield put({
      type: SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* SWITCH_PROJECT({ type, payload: { projectId } }) {
  NProgress.start()
  localStorage.setItem(PROJECT_ID_STORAGE_KEY, projectId)
  yield bootstrap(projectId)

  // Load user settings, this will update the navigation menu
  yield call(LOAD_USER_SETTINGS)

  // setup moment locale for project calendar. Will help with calculating business days
  yield call(SET_MOMENT_LOCALE)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      isSwitchingProjects: false,
    },
  })
  yield history.push({
    pathname: '/',
    search: `?redirect_to=${encodeURIComponent(history.location.pathname)}`,
  })
  NProgress.done()
}

export default function* rootSaga() {
  yield all([
    takeEvery(userActions.LOGIN, LOGIN),
    takeEvery(userActions.LOGOUT, LOGOUT),
    takeEvery(userActions.SEND_PASSWORD_RESET_EMAIL, SEND_PASSWORD_RESET_EMAIL),
    takeLeading(userActions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(userActions.SWITCH_PROJECT, SWITCH_PROJECT),
  ])
}
