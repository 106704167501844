import actions from './actions'
import { isEqual } from 'lodash'
import { AssetDataWithLocation, SearchApiAssetDataProp } from 'config/interfaces'

interface Data {
  rawData: SearchApiAssetDataProp[] | null
  enrichedData: AssetDataWithLocation[] | null
}

export interface AssetsReducerData {
  isFetching: boolean
  firstFetchFailureTime: Date
  lastFetchFailed: boolean
  lastFetchStartTime?: Date
  lastFetchSuccessTime?: Date
  data: Data
}

const initialState: AssetsReducerData = {
  isFetching: false,
  lastFetchFailed: false,
  firstFetchFailureTime: undefined,
  lastFetchStartTime: undefined,
  lastFetchSuccessTime: undefined,
  data: { rawData: null, enrichedData: null },
}

export default function assetsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_ASSETS:
      return {
        ...state,
        isFetching: true,
        lastFetchStartTime: new Date(),
      }
    case actions.FETCH_ALL_ASSETS_SUCCESS:
      const deepEqual = isEqual(state.data.rawData, action.payload.rawData)

      return {
        ...state,
        isFetching: false,
        lastFetchFailed: false,
        lastFetchSuccessTime: new Date(),
        firstFetchFailureTime: undefined,
        data: deepEqual ? state.data : action.payload, // only swap when data is updated
      }
    case actions.FETCH_ALL_ASSETS_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastFetchFailed: true,
        firstFetchFailureTime: state.firstFetchFailureTime || new Date(),
      }
    default:
      return state
  }
}
