import ynomiaclient from '../Client'
import { DateFilter, TimeControlsMode } from 'components/widgets/TimeControl/interface'
import { camelCase } from 'lodash'
import { Bootstrap } from '@ynomia/core/dist/blueprint'

export default class TrackableReport {
  static useClient: any

  static async fetchAll(client?: any, dateFilter?: DateFilter): Promise<any> {
    TrackableReport.useClient = client ? client : ynomiaclient
    let url
    let additionalQueryParams

    let dateQuery = ''
    if (dateFilter) {
      dateQuery = buildDateQuery(dateFilter)
      if (dateFilter.mode === TimeControlsMode.LIVE) {
        url = '/reports/v0/report/events/live-trackables'
        additionalQueryParams = '&returnType=array'
      } else {
        url = '/reports/v0/report/events/history/v1'
        additionalQueryParams = '&returnType=map&total=true'
      }
    }

    try {
      const bootstrap: Bootstrap = TrackableReport.useClient.cache.current.bootstrap
      const { scratchProjectCode } = bootstrap?.project?.metadata
      // console.log('dateQuery', dateQuery)
      // console.log(
      //   'url',
      //   `${url}?project=${scratchProjectCode}&scope=trackable${additionalQueryParams}${dateQuery}`
      // )

      const response = await TrackableReport.useClient.server.get(
        `${url}?project=${scratchProjectCode}&scope=trackable${additionalQueryParams}${dateQuery}`
      )

      return response.data
    } catch (err) {
      return err
    }
  }

  static async fetchRecentHistory(client?: any, dateFilter?: DateFilter): Promise<any> {
    TrackableReport.useClient = client ? client : ynomiaclient
    console.log('fetchRecentHistory in TrackableReport', dateFilter)
    let dateQuery = ''
    if (dateFilter) {
      dateQuery = buildDateQuery(dateFilter)
    }

    try {
      const bootstrap = TrackableReport.useClient.cache.current.bootstrap
      const { scratchProjectCode } = bootstrap?.project?.metadata
      const url = '/reports/v0/report/events/history/recent'
      const response = await TrackableReport.useClient.server.get(
        `${url}?project=${scratchProjectCode}${dateQuery}&bootstrap=true&group=time`
      )

      return response.data.value
    } catch (err) {
      return err
    }
  }

  static async fetchMatrackData(client?: any): Promise<any> {
    TrackableReport.useClient = client ? client : ynomiaclient
    try {
      const bootstrap = TrackableReport.useClient.cache.current.bootstrap
      const { matrakProjectId, matrakRecordType} = bootstrap?.project?.metadata
      const url = '/reports/v0/report/matrak/status/asset-summary'
      const response = await TrackableReport.useClient.server.get(
        `${url}?project=${matrakProjectId}&record_type=${matrakRecordType}`
      )
      return response.data.value
    } catch (err) {
      return err
    }
  }
}

const formatMode = (s: string) => {
  return camelCase(s)
}

export const buildDateQuery = (dateFilter: DateFilter): string => {
  if (!dateFilter) return ''
  let dateQuery = ''
  const { startDate, endDate, mode, timezone } = dateFilter

  if (mode) dateQuery += `&dateFilterMode=${formatMode(mode)}`
  if (startDate) dateQuery += `&startDate=${startDate?.toISOString()}`
  if (endDate) dateQuery += `&endDate=${endDate?.toISOString()}`
  if (timezone) dateQuery += `&timezone=${encodeURIComponent(timezone)}`
  return dateQuery
}
