import { ZoneMeta, EdgeScreenStatus, ScreenProp } from '../../../config/interfaces'
import { showLockdown } from 'utils/features'

export default class ZoneHelper {
  isLockedDown = (zone: ZoneMeta) => {
    return showLockdown() && zone.edge_status === EdgeScreenStatus.live && !zone.jumping
  }

  zoneIsNotReturnable = (selectedZone) => {
    const { jumping, available, edge_status: edgeStatus } = selectedZone
    const edgeIsLive = edgeStatus === EdgeScreenStatus['live']

    if (showLockdown()) {
      // when it is lockdown enabled
      return jumping && !available && edgeIsLive
    } else {
      // when it is not enabled
      return false
    }
  }

  /**
   * This uses the zone object to determine what status to display
   * @param zone
   */
  getDigitalTwinStatusFromZone = (zone: ZoneMeta, showLockdown: boolean = false) => {
    if (isJumping(zone)) return 'ScreensJumping'
    else if (showLockdown) return 'LockedDown'
    else if (isAvailable(zone)) return 'Available'
    else if (isTaken(zone)) return 'Taken'
  }

  /**
   * This uses the screen object to determine what status to display
   * @param screen
   */
  getDigitalTwinScreenStatusFromZone = (screen: ScreenProp, lockdownVisibility: Boolean) => {
    let out = 'SCREEN_UNSET'

    if (screen.meta.screen_status === 'secured') {
      out = 'SCREEN_NORMAL'
    }

    if (screen.meta.screen_status === 'live') {
      out = 'SCREEN_WARNING'
    }

    if (lockdownVisibility && screen.meta.screen_status === 'live' && !screen.zone.jumping) {
      out = 'SCREEN_CRITICAL'
    }
    return out
  }

  renderOccupantText = (zone: ZoneMeta) => {
    if (zone) {
      return zone.available ? 'Available' : zone.booking?.contractor
    }
  }

  selectionsAreValid = (existingSelection, newSelection) => {
    const existingIsLockedDown = this.isLockedDown(existingSelection)
    const newIsLockedDown = this.isLockedDown(newSelection)
    const anyAreLockedDown = existingIsLockedDown || newIsLockedDown
    const notAvailableAndTakenCombination = existingSelection.available !== newSelection.available

    return notAvailableAndTakenCombination || !newSelection.available || anyAreLockedDown
  }
}

const isJumping = (zone: ZoneMeta) => {
  const { jumping, available } = zone
  return !available && jumping
}

const isAvailable = (zone: ZoneMeta) => {
  return zone.available
}

const isTaken = (zone: ZoneMeta) => {
  return !zone.available
}
