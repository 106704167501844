import { put, all, call, takeEvery, select } from 'redux-saga/effects'
import { getUser } from '../user/reducers'
import actions from './actions'
import reportActions from '../report/actions'
import PoursService from '../../services/Pours'

export function* fetchAllPours() {
  const { project } = yield select(getUser)
  const projectCode = project?.metadata?.projectCode
  const response = yield call(PoursService.fetchAll, projectCode)
  yield put({
    type: actions.FETCH_ALL_POURS_SUCCESS,
    payload: { raw: response.raw, pours: response.pours },
  })
}

export function* updatePourToReport(payload) {
  yield put({
    ...payload,
    type: reportActions.UPDATE_REPORT_POURS
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_POUR_TO_REPORT_MIXEDPOUR, updatePourToReport),
    takeEvery(actions.FETCH_ALL_POURS, fetchAllPours)
  ])
}
