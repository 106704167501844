import { TrackableProp } from 'config/interfaces'
import moment from 'moment'

const LAST_HEARD_FRESH_THRESHHOLD = 4 // hours

/*
 * Transform the data from its search result format to teh Trackable type.
 */
export const formatTrackablesArraysFromSearchResults = (items: any): TrackableProp[] => {
  const n = LAST_HEARD_FRESH_THRESHHOLD
  let out = []
  try {
    out = items.map((item) => {
      const cur = item?.trackable?.tracking[0] || {}
      return {
        id: item._id,
        label: item.label,
        assetType: cur.assetType,
        lon: cur.lon,
        lat: cur.lat,
        alt: cur.alt,
        lastHeard: cur.updatedAt,
        isStale: moment(cur.updatedAt).isBefore(moment().subtract(n, 'hours')),
        layers: cur.layers,
        rtLayers: cur.rtLayers,
        updated: item.updated,
        created: item.created,
      }
    })
  } catch (err) {
    console.log('Failed to transform trackables from search results:', err)
    throw Error(err)
  }

  return out
}
