import actions from './actions'

const initialState = {
  data: [],
  isFetching: false,
}

export default function photosReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_PHOTOS:
      return { ...state, isFetching: true }
    case actions.FETCH_ALL_PHOTOS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      }
    default:
      return state
  }
}
