import { TrackableProp, IdsIndex } from 'config/interfaces'
import { reject } from 'lodash'
import { keyBy, selectLongestArrayOfArrays } from './processors'
import { Layerv2 as Layer } from 'config/interfaces'

export class LayerTreeHelper {
  idx: { [key: string]: Layer } // index of layers, accessible by id
  children: { [key: string]: Layer[] } // children by parentID
  primaryLayers: { [key: string]: Layer } // store all layers that are primary(levels)
  rootLayer: Layer[]

  constructor(layers: Layer[]) {
    // index the input
    this.idx = {}
    this.children = {}
    this.primaryLayers = {}
    this.rootLayer = null

    for (let l of layers) {
      this.idx[l.id] = l
      this.children[l.id] = []

      if (l.isPrimary) {
        this.primaryLayers[l.id] = l
      }
    }
    for (let l of layers) {
      if (l.parentID && l.parentID in this.children) {
        this.children[l.parentID].push(l)
      }
    }

    this.rootLayer = layers.filter(layer => layer.isRoot)
  }

  // Now we have a structure, we can add methods that work with the tree
  get(id: string): Layer {
    return this.idx[id]
  }
  getMany(ids: string[]): Layer[] {
    return ids.map(id => this.idx[id]).filter(id => id !== undefined)
  }
  getChildrenOf(id: string): Layer[] {
    return this.children[id] || []
  }
  getAncestorsOf(id: string): Layer[] {
    let current = this.idx[id]
    let ancestors = []
    while (current !== undefined) {
      ancestors.unshift(current)
      current = this.idx[current.parentID]
    }
    return ancestors
  }

  getPrimaryLayers() {
    return this.primaryLayers
  }

  getRootLayer() {
    return this.rootLayer
  }
}

export const enrichLayers = (layers: Layer[], layerHelper: LayerTreeHelper) => {
  const mapped = layers.map((layer: Layer) => {
    const siblings = reject(layerHelper.getChildrenOf(layer.parentID), ['id', layer.id])
    return {
      ...layer,
      isRoot: !!layer.isRoot,
      parent: layerHelper.get(layer.parentID),
      siblings: siblings.length ? siblings : undefined,
      ancestors: layerHelper.getAncestorsOf(layer.id),
      directChild: layerHelper.getChildrenOf(layer.id),
    }
  })
  return keyBy(mapped, 'id')
}

/**
 * Generates a new keys with the Labels for the trackable to be use to show it's current location
 * @param data
 * @param layerHelper
 * @param idsIndex
 */
export const applyLayerTree = (
  data: TrackableProp[],
  layerHelper: LayerTreeHelper,
  idsIndex: IdsIndex
) => {
  if (!data?.length) return []

  const buildArrayMapForRtLayers = (rtLayers) => {
    return rtLayers.map(l =>
      layerHelper.getAncestorsOf(l).map(el => ({
        ...el,
        label: idsIndex[el?.id]?.label || 'MISSING_LABEL',
      }))
    )
  }

  return data.map((trackable: TrackableProp) => {
    return {
      ...trackable,
      layerObjects: trackable?.rtLayers?.length
        ? selectLongestArrayOfArrays(buildArrayMapForRtLayers(trackable?.rtLayers))
        : [],
    }
  })
}
