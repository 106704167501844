import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge, Switch } from 'antd'
import styles from './style.module.scss'

interface Props {
  [x: string]: any
}

interface State {
  [x: string]: any
}

@connect(({ user, settings }) => ({ user, settings }))
class ProfileMenu extends React.Component<Props, State> {
  state = {
    count: 0,
  }

  changeSetting = (setting, value) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting,
        value,
      },
    })
  }

  logout = (e) => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  render() {
    const { user } = this.props
    const { count } = this.state

    const {
      settings: { presenterMode },
    } = this.props

    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />
            {`, ${user.auth?.userInfo?.name || 'Administrator'}`}
          </strong>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className={styles.air__sidebar__item}>
            <div className={styles.air__sidebar__label}>
              Presenter Mode &nbsp;
              <Switch
                checked={presenterMode}
                onChange={(value) => {
                  this.changeSetting('presenterMode', value)
                }}
              />
            </div>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="#" onClick={this.logout}>
            <i className={`${styles.menuIcon} fe fe-log-out`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Badge count={count}>
            <Avatar
              className={styles.avatar}
              shape="square"
              size="large"
              icon={<UserOutlined />}
              src={user.auth?.userInfo?.picture}
            />
          </Badge>
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
