import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import actions from './actions'
import { fetchReport } from 'services/report'
import { getUser } from '../user/reducers'
import PoursService from 'services/Pours'
import { DashboardStructureHelper } from 'pages/dashboard/facade/status/helper/dashboardStructureHelper'

export function* GET_REPORT(ctx) {
  const {
    name,
    status,
    selectedLevel,
    selectedDateRange,
    asset_names,
    projectId,
    record_type,
    asName,
  } = ctx.payload
  const { project } = yield select(getUser)
  const site = project?.metadata?.site
  let projectCode = projectId || ctx.payload.projectCode
  if (!projectCode) {
    projectCode = project?.metadata?.projectCode
  }

  const { data } = yield call(
    fetchReport,
    name,
    projectCode,
    status,
    site,
    selectedDateRange,
    asset_names,
    record_type
  )

  let payload = {}
  payload[asName ? asName : name] = {
    meta: { lastFetched: new Date() },
    values: data.value,
    original: data.value,
    selectedLevel: selectedLevel,
  }

  yield put({
    type: 'report/SET_REPORT',
    payload,
  })
}

export function* GET_REPORT_WITH_POURS(ctx) {
  const { name, status, selectedLevel } = ctx.payload
  const { project } = yield select(getUser)
  let projectCode = ctx.payload.projectCode
  if (!projectCode) {
    projectCode = project?.metadata?.projectCode
  }

  try {
    const { data: hvmtData } = yield call(fetchReport, name, projectCode, status)
    const { raw: telemData } = yield call(PoursService.fetchAll, projectCode)
    const { predefined_assets: blueprintsStructureData } = project.structure.pours
    const apiData = {
      hvmtData: hvmtData.value,
      telemData,
      blueprintsStructureData,
    }
    const combinedData = new DashboardStructureHelper().compileDataFromApis(
      apiData.hvmtData,
      apiData.telemData,
      apiData.blueprintsStructureData
    )
    let payload = {
      [name]: {
        meta: { lastFetched: new Date() },
        values: hvmtData.value,
        original: hvmtData.value,
        selectedLevel: selectedLevel,
        mixedPour: combinedData,
      },
      API_CACHE_DATA: apiData,
    }
    yield put({
      type: 'report/SET_REPORT',
      payload,
    })
  } catch (e) {
    console.log(e)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_REPORT, GET_REPORT),
    takeEvery(actions.GET_REPORT_WITH_POURS, GET_REPORT_WITH_POURS),
  ])
}
