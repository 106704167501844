const actions = {
  UPDATE_NM_STATE: 'networkManagement/update',
  HANDLE_BEACON_CHANGE: 'layers/update_beacons',
  FETCH_ALL_LAYERS: 'layers/fetch_all_layers',
  FETCH_ALL_LAYERS_V2: 'layers/fetch_all_layers_v2',
  FETCH_ALL_LAYERS_SUCCESS: 'layers/fetch_all_layers_success',
  FETCH_ALL_LAYERS_FAILURE: 'layers/fetch_all_layers_failure',
  RESET_STATE: 'layers/reset_state',
  ADD_LAYERS: 'layers/add_layers',
  ADD_LAYERS_SUCCESS: 'layers/add_layers_success',
  ADD_LAYERS_FAILURE: 'layers/add_layers_failure',
}

export default actions
