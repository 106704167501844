const actions = {
  FETCH_ALL_ZONES: 'zones/fetch_all',
  FETCH_ALL_ZONES_SUCCESS: 'zones/fetch_all_success',
  FETCH_ALL_ZONES_FAILURE: 'zones/fetch_all_failure',
  SELECT_ZONE: 'zones/select',
  DESELECT_ZONE: 'zones/deselect',
  DESELECT_ALL_ZONES: 'zones/deselect_all',
  ZONES_ARE_SENDING: 'zones/sending',
  UPDATE_ZONE: 'zones/update_one',
}

export default actions
