import actions from './actions'

const initialState = {}

export default function screenReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SCREEN_IS_SENDING:
      return { ...state, isSending: action.payload }
    default:
      return state
  }
}
