import { put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import client from '../../services/Client'
import TS from 'services/Tracking'

import { formatTrackablesArraysFromSearchResults } from './helpers'

export function* fetchAllTrackables() {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      // This saga is designed to always run in the background (due to the critical nature of
      // the data it fetches), but we don't want to execute it if the user becomes unauthenticated.
      return
    }
    let trackables = yield call(TS.TrackableService.fetchAll, client)

    // Trackables data is transformed to a simpler,
    // flatter object before storing on redux state.
    try {
      trackables = formatTrackablesArraysFromSearchResults(trackables)
    } catch (err) {
      console.log('Could not transform trackable data within saga', err)
    }

    yield put({
      type: actions.FETCH_ALL_TRACKABLE_SUCCESS,
      payload: trackables,
    })
  } catch {
    yield put({ type: actions.FETCH_ALL_TRACKABLE_FAILURE })
  }
}

export function* fetchAllTrackablesReport(ctx?: any) {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      return
    }

    let trackables = yield call(TS.TrackableReportService.fetchAll, client, ctx.payload)

    // Trackables data is transformed to a simpler,
    // flatter object before storing on redux state.
    try {
      if (Array.isArray(trackables)) {
        trackables = formatTrackablesArraysFromSearchResults(trackables)
      } else {
        trackables = trackables.value
      }
    } catch (err) {
      console.log('Could not transform trackable data within saga', err)
    }

    yield put({
      type: actions.FETCH_ALL_TRACKABLE_REPORT_SUCCESS,
      payload: trackables,
    })
  } catch {
    yield put({ type: actions.FETCH_ALL_TRACKABLE_REPORT_FAILURE })
  }
}

export function* fetchRecentHistory(ctx?: any) {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      // This saga is designed to always run in the background (due to the critical nature of
      // the data it fetches), but we don't want to execute it if the user becomes unauthenticated.
      return
    }
    let recentHistory = yield call(
      TS.TrackableReportService.fetchRecentHistory,
      client,
      ctx.payload
    )

    yield put({
      type: actions.FETCH_RECENT_HISTORY_SUCCESS,
      payload: { asMinuteIndex: recentHistory },
    })
  } catch {
    yield put({ type: actions.FETCH_RECENT_HISTORY_FAILURE })
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.FETCH_ALL_TRACKABLE, fetchAllTrackables)
  yield takeEvery(actions.FETCH_ALL_TRACKABLE_REPORT, fetchAllTrackablesReport)
  yield takeEvery(actions.FETCH_RECENT_HISTORY, fetchRecentHistory)
}
