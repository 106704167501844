import actions from './actions'

const initialState = {
  menuData: [
    {
      title: 'Modules',
      category: true,
    },
  ],
}

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
