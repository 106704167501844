import { put, call, takeEvery, select } from 'redux-saga/effects'
import { getUser } from '../user/reducers'
import actions from './actions'
import PhotosService from '../../services/Photos'

export function* fetchAllPhotos() {
  const { project } = yield select(getUser)
  const projectCode = project?.metadata?.projectCode
  const payload = yield call(PhotosService.fetchAll, projectCode)
  yield put({
    type: actions.FETCH_ALL_PHOTOS_SUCCESS,
    payload,
  })
}

export default function* rootSaga() {
  yield takeEvery(actions.FETCH_ALL_PHOTOS, fetchAllPhotos)
}
