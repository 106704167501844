import { Observable, Subscription } from 'rxjs'
import { InMemoryCacheData } from '@ynomia/client'
import { LIVE_DATA_PROJECT_CODES } from '../../config/constants'
import { store } from '../../redux/store'
import userActions from '../../redux/user/actions'
import { keyBy, LayerTreeHelper, enrichLayers } from 'utils'

interface ClientCacheObservables {
  onChange$: Observable<InMemoryCacheData>
}

/**
 * This module listens to updates in the Ynomia Client SDK in-memory cache.
 */
class CacheObserver {
  private onChangeSubscription?: Subscription

  /**
   * Use this method after establishing a new `InMemoryCache` instance for `@ynomia/client` to
   * subscribe this observer class to the provided state-change observable.
   * @param {ClientCacheObservables} clientCacheObservables
   * @return {void}
   */
  initialize({ onChange$ }: ClientCacheObservables): void {
    // 1. Unsubscribe from any existing auth observations to avoid memory leaks.
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    // 2. Subscribe to the new observables and bind them to their own event handlers.
    this.onChangeSubscription = onChange$.subscribe(CacheObserver.onChange)
  }

  /**
   * @event onChange
   * Emits every time the Client SDK cache is updated.
   * @param {InMemoryCacheData} clientCache
   * @return {void}
   */
  private static onChange(clientCache: InMemoryCacheData): void {
    const { auth, bootstrap, projectId } = clientCache

    // if ids are present on bootstrap.project, index them by id.
    if (bootstrap?.project?.ids) {
      bootstrap.project.idsIndex = keyBy(bootstrap.project.ids, 'id')
    }

    if (bootstrap?.project?.layers) {
      const layerHelper = new LayerTreeHelper(bootstrap?.project?.layers)
      const primaryLayers = layerHelper.getPrimaryLayers()
      bootstrap.project.decoratedLayers = {
        rootLayer: layerHelper.getRootLayer(),
        primaryLayers: primaryLayers,
        enrichedLayers: enrichLayers(bootstrap?.project?.layers, layerHelper),
        isMultiLevel: Object.keys(primaryLayers).length > 1,
      }
    }

    store.dispatch({
      type: userActions.SET_STATE,
      payload: {
        auth,
        project: bootstrap?.project || {},
        projects: bootstrap?.projects || [],
        client: bootstrap?.project?.metadata?.client,
        selectedProjectId: projectId,
        liveData: LIVE_DATA_PROJECT_CODES.includes(bootstrap?.project?.metadata?.projectCode),
        // @todo Redundant properties that should be eventually removed!
        id: 'hello',
        name: 'Administrator',
        email: 'world',
        avatar: 'foo.jpg',
        role: 'admin',
      },
    })
  }
}

const cacheObserver = new CacheObserver()

export default cacheObserver
