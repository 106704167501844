import React from 'react'
import ProjectSwitcher from './ProjectSwitcher'
import UserMenu from './UserMenu'
import Status from './Status'
import style from './style.module.scss'
import { connect } from 'react-redux'

const mapStateToProps = ({ settings }) => ({ settings })

interface Props {
  [x: string]: any
}

@connect(mapStateToProps)
class TopBar extends React.Component<Props> {
  render() {
    const {
      settings: { presenterMode },
    } = this.props

    return (
      <div className={style.topbar}>
        <div className="mr-md-8 mr-auto">
          {presenterMode && (
            <img src="resources/images/ynomia-full-logo-dark.svg" alt="Ynomia Logo" />
          )}
          {!presenterMode && <b>YNOMIA &nbsp;|&nbsp; CONSTRUCTION HQ</b>}
        </div>
        <div className="mr-4">
          <Status />
        </div>
        <div className="mr-4 d-none d-sm-block">
          <ProjectSwitcher />
        </div>
        <div className="">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
