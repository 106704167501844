import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

interface Props {
  [x: string]: any
}

interface State {
  [x: string]: any
}

@connect(mapStateToProps)
class FooterDark extends React.Component<Props, State> {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footerDark, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={`py-5 ${style.mainContainer}`}>
          <div className={style.container}>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <h4 className="text-white font-weight-bold mb-4 mb-lg-5">Quick Links</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="d-flex flex-column">
                      <a className="mb-1" href="#">
                        Documentation
                      </a>
                      <a className="mb-1" href="#">
                        Service Rules
                      </a>
                      <a className="mb-1" href="#">
                        User Settings
                      </a>
                      <a className="mb-1" href="#">
                        Dashboard Setings
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex flex-column mb-4">
                      <a className="mb-1" href="#">
                        User Settings
                      </a>
                      <a className="mb-1" href="#">
                        Dashboard Settings
                      </a>
                      <a className="mb-1" href="#">
                        Documentation
                      </a>
                      <a className="mb-1" href="#">
                        Service Rules
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <h4 className="text-white font-weight-bold mb-4 mb-lg-5">Get In Touch</h4>
                <input
                  className={`form-control mb-4 ${style.emailInput}`}
                  type="email"
                  placeholder="Enter your email"
                />
              </div>
              <div className="col-md-12 col-lg-4">
                <h4 className="text-white font-weight-bold mb-4 mb-lg-5">About</h4>
                <p>
                  Ynomia offer a simple end-to-end technology solution that creates a connected
                  jobsite. Giving worksite personnel the ability to locate materials, tools, and
                  people across the site in real time - we put the supply chain in the hands of the
                  builders.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`py-4 ${style.bottomContainer}`}>
          <div className={style.container}>
            <div className="d-sm-flex align-items-sm-center">
              <div className={`clearfix mb-3 mr-sm-auto ${style.logo}`}>
                <img src="resources/images/ynomia-full-logo-light.svg" alt="Ynomia Logo" />
              </div>
              <div className="d-flex flex-column flex-sm-row">
                <a className="mb-1 mb-sm-0 px-sm-3" href="#">
                  About
                </a>
                <a className="mb-1 mb-sm-0 px-sm-3" href="#">
                  Terms of Use
                </a>
                <a className="mb-1 mb-sm-0 px-sm-3" href="#">
                  Buy Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FooterDark
