import { all, takeEvery, put, select } from 'redux-saga/effects'
import updateActions from './actions'
import { getLastChecked } from './reducers'
import { sameDay } from '../../utils/time'

export function* CHECK_FOR_UPDATE() {
  const lastChecked = yield select(getLastChecked)
  const now = new Date(Date.now())

  if (!sameDay(now, lastChecked)) {
    // Perform a page refresh
    window.location.reload()
    return
  }

  yield put({
    type: updateActions.SET_STATE,
    payload: { lastChecked: now },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(updateActions.CHECK_FOR_UPDATE, CHECK_FOR_UPDATE)])
}
