import client from './Client'

export function fetchReport(
  reportName,
  projectCode,
  status = [],
  site?,
  selectedDateRange?,
  asset_names?,
  record_type?
) {
  const reportPath = {
    ALL_ASSETS: 'hvmt/status/per-asset',
    LEVEL_SUMMARY: 'hvmt/status/per-level',
    RUN_RATE: 'hvmt/status/run-rate',
    ASSET_SUMMARY: 'hvmt/status/asset-summary',
    HATCH_SUMMARY: 'hatch',
    DEVICES_SUMMARY: 'devices',
    CUMULATIVE_STATUS: 'hvmt/status/cumulative',
    MATRAK_SUMMARY: 'matrak/status/asset-summary',
  }
  /**
   * Generate the report API Request Parameters.
   */
  const selectedReport = reportPath[reportName]
  const statusWhitelistList = encodeURIComponent(status.join(','))
  const urlParams = new URLSearchParams(window.location.search)
  const reportApiVersion = urlParams.get('reportApiVersion') || 1

  let start, end
  if (selectedDateRange) {
    start = selectedDateRange[0].format('MM-DD-YYYY')
    end = selectedDateRange[1].format('MM-DD-YYYY')
  }

  /**
   * We define a manual request configuration here so that we are able to customise the base
   * url when calling the reports service on localhost during development.
   */
  return client.server.get(`/reports/v0/report/${selectedReport}`, {
    params: {
      project: projectCode,
      status: statusWhitelistList,
      version: reportApiVersion,
      site,
      start,
      end,
      asset_names,
      record_type,
    },
  })
}
