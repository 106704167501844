import actions from './actions'
import predefinedStructure from './data/predefined_structure.json'

const initialState = {
  data: {},
  isFetching: false,
  isSending: false,
  predefinedStructure: predefinedStructure,
  raw: [],
}

export default function poursReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_POURS:
      return { ...state, isFetching: true }
    case actions.FETCH_ALL_POURS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.pours,
        raw: action.payload.raw,
      }
      case actions.POURS_ARE_SENDING:
        return { ...state, isSending: action.payload }
      case actions.UPDATE_POUR_TO_REPORT_MIXEDPOUR:
        return state
    default:
      return state
  }
}
