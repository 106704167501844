import { put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import ZonesService from '../../services/Zones'
import client from '../../services/Client'

export function* fetchAllZones() {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      // This saga is designed to always run in the background (due to the critical nature of
      // the data it fetches), but we don't want to execute it if the user becomes unauthenticated.
      return
    }
    const zones = yield call(ZonesService.fetchAll)

    yield put({
      type: actions.FETCH_ALL_ZONES_SUCCESS,
      payload: zones,
    })
  } catch {
    yield put({ type: actions.FETCH_ALL_ZONES_FAILURE })
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.FETCH_ALL_ZONES, fetchAllZones)
}
