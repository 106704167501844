import { put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import client from '../../services/Client'
import TS from 'services/Tracking'
import { keyBy } from 'lodash'
import moment from 'moment'

const LAST_HEARD_FRESH_THRESHHOLD = 4

export function* fetchAllAssets() {
  try {
    if (!client.cache.current.auth.isAuthenticated) {
      return
    }

    let searchedAssets = yield call(TS.TrackableService.fetchAll, client, { observations: true })
    const enrichedAssets = enrichingAssets(
      searchedAssets,
      client.cache.current.bootstrap.project.asset_types,
      client.cache.current.bootstrap.project.idsIndex
    )

    yield put({
      type: actions.FETCH_ALL_ASSETS_SUCCESS,
      payload: { rawData: searchedAssets, enrichedData: enrichedAssets },
    })
  } catch {
    yield put({ type: actions.FETCH_ALL_ASSETS_FAILURE })
  }
}

export const enrichingAssets = (assets, assetTypes, idsIndex) => {
  return assets.map(({ observations, trackable, status, destination, ...idsData }) => {
    const assetTypesKeys = keyBy(assetTypes.types, 'id')
    const statusKey = keyBy(assetTypesKeys[idsData.type].statuses, 'id')
    const selectedStatus = statusKey[status]
    let enrichAssetObject = {}
    enrichAssetObject['idsData'] = {
      ...idsData,
      statusLabel: selectedStatus?.label,
      statusId: selectedStatus?.id,
      twinID: destination?.twinID,
      destinationLayerID: destination?.layerID,
      destinationLayerLabel: destination ? idsIndex[destination?.layerID]?.label : null,
    }

    if (trackable?.tracking?.length) {
      const currentTrack = trackable.tracking[0]
      enrichAssetObject['trackableData'] = {
        ...currentTrack,
        id: idsData.id,
        label: idsData.label,
        lastHeard: currentTrack.updatedAt,
        isStale: moment(currentTrack.updatedAt).isBefore(
          moment().subtract(LAST_HEARD_FRESH_THRESHHOLD, 'hours')
        ),
      }
    } else {
      // This creates a temporary listing for trackables that we have info of it in IDs collection but doesn't exist on trackable collection
      enrichAssetObject['trackableData'] = {
        id: idsData.id,
        assetType: idsData.type,
        label: idsData.label,
        lastHeard: null,
        isStale: null,
      }
    }

    if (observations?.length) {
      enrichAssetObject['observationData'] = enrichObservationData(
        observations,
        enrichAssetObject,
        statusKey
      )
    }

    return { ...enrichAssetObject, id: idsData._id, contains: Object.keys(enrichAssetObject) }
  })
}

export function enrichObservationData(observations, enrichAssetObject, statusKey) {
  enrichAssetObject['observationData'] = {
    statusChanges: {},
    notes: [],
  }
  observations.forEach((observation) => {
    const obervationType = observation?.type?.split('::')[2]
    if (obervationType && obervationType.includes('status_update_observation')) {
      enrichAssetObject['observationData']['statusChanges'] = {
        ...enrichAssetObject['observationData']['statusChanges'],
        [statusKey[observation.to]?.label || observation.to]: {
          date: moment(observation.date)?.format('YYYY-MM-DD'),
          time: moment(observation.date)?.format('HH:mm:ss'),
          dateTime: observation.date, // This is the UTC timestring we want to retain
          type: obervationType.includes('auto') ? 'auto' : 'manual',
          userID: observation.userID,
        },
      }
    }

    // TODO : Most of the field in this require some work in scratch.
    // { timestamp, assetType,  assetName, fields, layerID, layerName, statusID, statusName, userID, userName}
    if (observation?.type === 'qc') {
      enrichAssetObject['observationData']['notes'].push(observation)
    }
  })

  return enrichAssetObject['observationData']
}

export default function* rootSaga() {
  yield takeEvery(actions.FETCH_ALL_ASSETS, fetchAllAssets)
}
