import React from 'react'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import classNames from 'classnames'
import userActions from '../../../../redux/user/actions'
import styles from './style.module.scss'

interface Props {
  [x: string]: any
}

interface State {
  [x: string]: any
}

@connect(({ user }) => ({ user }))
class ProjectSwitcher extends React.Component<Props, State> {
  changeProject = async ({ key: projectId }) => {
    this.props.dispatch({
      type: userActions.SWITCH_PROJECT,
      payload: {
        projectId,
      },
    })
  }

  render() {
    const {
      user: { isSwitchingProjects, selectedProjectId, projects, project },
    } = this.props
    const projectList = projects.map((p) => (
      <Menu.Item key={p.id} disabled={isSwitchingProjects}>
        {p.name?.toUpperCase()} ({p.tenant?.toUpperCase()})
      </Menu.Item>
    ))
    const menu = (
      <Menu selectedKeys={[selectedProjectId]} onClick={this.changeProject}>
        {projectList}
      </Menu>
    )
    return (
      <Dropdown
        disabled={isSwitchingProjects}
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        className={classNames({ [styles.switching_projects]: isSwitchingProjects })}
      >
        <div className={styles.dropdown}>
          <span className="text-uppercase">
            {project.metadata.name} ({project.metadata.tenant})
          </span>
        </div>
      </Dropdown>
    )
  }
}

export default ProjectSwitcher
