import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import { getDefaultRoute } from './services/defaultRoute'
import { parseQueryString } from './utils/processors'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'


const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Dashboards
  {
    path: '/dashboard/timelapsecamera',
    Component: loadable(() => import('pages/dashboard/timelapseCamera')),
    exact: true,
  },
  {
    path: '/dashboard/status',
    Component: loadable(() => import('pages/dashboard/facade/status')),
    exact: true,
  },
  {
    path: '/dashboard/progress',
    Component: loadable(() => import('pages/dashboard/facade/progress')),
    exact: true,
  },
  {
    path: '/module/devices/monitoring',
    Component: loadable(() => import('pages/dashboard/devices/monitoring')),
    exact: true,
  },
  {
    path: '/module/facade/status',
    Component: loadable(() => import('pages/dashboard/facade/status')),
    exact: true,
  },
  {
    path: '/module/facade/progress',
    Component: loadable(() => import('pages/dashboard/facade/progress')),
    exact: true,
  },
  {
    path: '/module/hvmt/:type/status',
    Component: loadable(() => import('pages/dashboard/hvmt/status')),
    exact: true,
  },
  {
    path: '/module/zones',
    Component: loadable(() => import('pages/dashboard/zones')),
    exact: true,
  },
  {
    path: '/module/zones/history',
    Component: loadable(() => import('pages/dashboard/zones/history')),
    exact: true,
  },
  {
    path: '/module/screens/hatches',
    Component: loadable(() => import('pages/dashboard/screens/hatches')),
    exact: true,
  },
  {
    path: '/module/screens/safetyscreens',
    Component: loadable(() => import('pages/dashboard/screens/SafetyScreensDashboard')),
    exact: true,
  },
  {
    path: '/examples/forms',
    Component: loadable(() => import('pages/examples/forms')),
    exact: true,
  },
  {
    path: '/module/structure/status',
    Component: loadable(() => import('pages/dashboard/structure')),
    exact: true,
  },
  {
    path: '/dashboard/structure/status',
    Component: loadable(() => import('pages/dashboard/facade/status/index_alt.tsx')),
    exact: true,
  },
  {
    path: '/module/structure/sensors',
    Component: loadable(() => import('pages/dashboard/structure/sensors')),
    exact: true,
  },
  {
    path: '/module/activity/report',
    Component: loadable(() => import('pages/dashboard/activity/report')),
    exact: true,
  },
  {
    path: '/module/activity/map',
    Component: loadable(() => import('pages/dashboard/activity/map')),
    exact: true,
  },
  {
    path: '/dashboard/progress/statustracking',
    Component: loadable(() => import('pages/dashboard/statusTracking')),
    exact: true,
  },

  // Configuration Pages
  {
    path: '/configure/network',
    Component: loadable(() => import('pages/dashboard/networkManagement')),
    exact: true,
  },
  {
    path: '/configure/layers',
    Component: loadable(() => import('pages/dashboard/configuration/layersConfiguration/')),
    exact: true,
  },

  // System Pages
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
  {
    path: '/events/explore',
    Component: loadable(() => import('pages/events/explore')),
    exact: true,
  },
  {
    path: '/events/viewer',
    Component: loadable(() => import('pages/events/view')),
    exact: true,
  },
  {
    path: '/files/:id',
    Component: loadable(() => import('pages/system/files')),
    exact: true,
  },
  {
    path: '/files',
    Component: loadable(() => import('pages/system/files-url')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  /**
   * <Redirect>'s render method happens multiple times when switching projects,
   * hence we store the new route until it's landed on
   */

  redirectRoute = undefined

  redirectHandler = () => {
    const { history } = this.props

    /**
     * redirect_to is utilised to navigate a user to their intended destination
     * just after login or the same destination when project switching.
     */
    const { redirect_to } = parseQueryString(history.location.search)
    const result = getDefaultRoute(redirect_to || this.redirectRoute)
    this.redirectRoute = result
    return <Redirect to={result} />
  }

  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props

    if (history.location.pathname !== '/') {
      this.redirectRoute = undefined
    }

    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={(props) => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route exact path="/" render={this.redirectHandler} />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
